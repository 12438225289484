import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import Spinner from '../Blocks/Spinner';

@inject('interfaceStore', 'locationStore', 'configStore')
@observer
export class OffersSelectCompetitorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      loading: false,
      visible: true
    };
  }

  componentDidMount = () => {
    this.setState({
      selected: this.props.locationStore.offerStoresMap.selected || []
    });
  };

  onClose = () => {
    this.setState({
      visible: false
    });
    setTimeout(() => {
      this.props.interfaceStore.hideModal();
    }, 1500);
  };

  onSelect = async e => {
    const checkbox = e.target;
    if (checkbox.checked) {
      this.setState({
        selected: [...this.state.selected, parseInt(checkbox.value)]
      });
    } else {
      this.setState({
        selected: this.state.selected.filter(
          id => id !== parseInt(checkbox.value)
        )
      });
    }
  };

  onSubmit = async () => {
    const { location, center, zoom } = this.props.locationStore.offerStoresMap;
    this.setState({
      loading: true
    });
    try {
      await this.props.locationStore.getManyCompetitorStoresMap({
        ids: this.state.selected,
        location,
        center,
        zoom,
        refresh: true,
        force: true
      });
    } catch (e) {
      console.error(e);
    }

    this.onClose();
    this.setState({
      loading: false
    });
  };

  render() {
    const title = i18n.t('SELECT_COMPETITORS');
    const submit = i18n.t('SUBMIT');
    return (
      <Modal
        show={this.state.visible}
        className="Modal SelectCompetitorModal pt-6"
        onHide={this.onClose}
      >
        <div className="modal-header">
          <h5 className="modal-title">{title}</h5>
          <button type="button" className="close" onClick={this.onClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="form">
            {Object.keys(this.props.configStore.COMPETITORS).map((key, i) => {
              const competitor = this.props.configStore.COMPETITORS[key];
              return (
                <div className="form-check" key={i}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={this.onSelect}
                    checked={this.state.selected.includes(parseInt(key))}
                    value={key}
                    id={`${competitor.name}Check`}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`${competitor.name}Check`}
                  >
                    {competitor.name}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary relative"
            disabled={this.state.loading}
            onClick={this.onSubmit}
          >
            {submit}
          </button>
        </div>
      </Modal>
    );
  }
}

export default OffersSelectCompetitorModal;
