import React, {Component} from 'react';
import {Spin} from 'antd';

class Spinner extends Component {
  render() {
    return (
      <div className="true-center">
        <Spin size="large" />
      </div>
    );
  }
}

export default Spinner;
