export const transformClientStore = store => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in store) {
    if (store[key] === null) {
      store[key] = '';
    }
  }

  return {
    ...store,
    address: `${store.addressLine1}
      ${store.addressLine2} ${store.addressLine3}
      ${store.addressLine4}
      ${store.locality}
      ${store.postcode}`
  };
};
