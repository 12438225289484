import sharedStrings from '../locales/i18n/return/shared';

const modalClass = 'modal-waiting';

const modalConfig = {
  success: {
    className: modalClass,
    title: sharedStrings.success,
    content: sharedStrings.success_01,
    okText: sharedStrings.ok
  },
  error: {
    className: modalClass,
    title: sharedStrings.error,
    content: sharedStrings.error_01,
    okText: sharedStrings.ok
  }
};

export default modalConfig;
