import { FC } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Menu } from 'antd';
import { StarOutlined } from '@ant-design/icons';

const ManageCreateCampaignSubmenu: FC = props => (
  <Menu.SubMenu
    title={(
      <span>
        <StarOutlined />
        <span>
          Campaigns
        </span>
      </span>
    )}
    {...props}
  >
    <Menu.Item key="/app/management/create-campaign">
      <Link to="/app/management/create-campaign">
        <span className="nav-text">
          Create Campaign
        </span>
      </Link>
    </Menu.Item>

    <Menu.Item key="/app/management/view-campaign">
      <Link to="/app/management/view-campaign">
        <span className="nav-text">
          View Campaign
        </span>
      </Link>
    </Menu.Item>

  </Menu.SubMenu>
);

export default observer(ManageCreateCampaignSubmenu);
