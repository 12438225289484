// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Header-module__header_iconLink___2KmDe{display:flex;height:40px}\n", ""]);
// Exports
exports.locals = {
	"header_iconLink": "Header-module__header_iconLink___2KmDe"
};
module.exports = exports;
