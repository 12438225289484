import { FC } from "react";
import { observer } from "mobx-react";

import { Menu } from "antd";
import { Link } from "react-router-dom";
import {
  AreaChartOutlined,
  BarChartOutlined,
  FundOutlined,
  HomeOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import { useStores } from "../../../hooks/useStores";
import sharedStrings from "../../../locales/i18n/return/shared";

// import AnalyticsSubmenu from '../Submenus/VenueNowSchool/Analytics/AnalyticsSubmenu';

const VenueNowSchoolSidebar: FC = () => {
  const { interfaceStore } = useStores();
  const menuTheme = interfaceStore.theme === "light" ? "light" : "dark";
  const venueNowHomepage = sharedStrings.venueNow.homepage;

  return (
    <Menu theme={menuTheme} mode="inline">
      <Menu.Item key="/app">
        <Link to="/app">
          <HomeOutlined />
          <span className="nav-text">{venueNowHomepage}</span>
        </Link>
      </Menu.Item>

      <Menu.ItemGroup title={interfaceStore.navActive && "Analytics"} />

      <Menu.Item key="/app/analytics/venuenow/number-of-visits">
        <Link to="/app/analytics/venuenow/number-of-visits">
          <span className="nav-text">
            <BarChartOutlined />
            <span>Visits Per Service</span>
          </span>
        </Link>
      </Menu.Item>

      <Menu.Item key="/app/analytics/venuenow/packages-taken">
        <Link to="/app/analytics/venuenow/packages-taken">
          <span className="nav-text">
            <FundOutlined />
            <span>Packages Taken Per Service</span>
          </span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/app/analytics/venuenow/missing-packages">
        <Link to="/app/analytics/venuenow/missing-packages">
          <span className="nav-text">
            <QuestionCircleOutlined />
            <span>Missing Packages</span>
          </span>
        </Link>
      </Menu.Item>

      <Menu.ItemGroup
        title={interfaceStore.navActive && "Location Analytics"}
      />

      <Menu.Item key="/app/analytics/location-analytics">
        <Link to="/app/analytics/location-analytics">
          <span className="nav-text">
            <AreaChartOutlined />
            <span>Location Statistics</span>
          </span>
        </Link>
      </Menu.Item>
    </Menu>
  );
};

export default observer(VenueNowSchoolSidebar);
