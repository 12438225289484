import React, {Component} from 'react';

export class Validation extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return this.props.isError ? (
      <div className="invalid-feedback">{this.props.children}</div>
    ) : null;
  }
}

export default Validation;
