import React, {Component} from 'react';
import {Trans as I18N} from 'react-i18next';

class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: props.theme || configStore.THEME.settings.theme,
      delay: false
    };
  }

  componentDidMount = () => {
    this.timeout = setTimeout(() => {
      this.setState({
        delay: true
      });
    }, 5000);
  };

  componentWillUnmount = () => {
    clearTimeout(this.timeout);
  };

  render() {
    const delayMessage = <I18N>DELAY_MESSAGE</I18N>;
    return (
      <div className="page-dashboard h-100 relative Loader">
        <div className="true-center">
          <div className="lds-grid">
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>

        {this.state.delay ? (
          <span
            className={`true-center mt-5 pt-5 font-weight-bold ${
              this.state.theme === 'dark' ? 'text-white' : ''
            }`}
          >
            {delayMessage}
          </span>
        ) : null}
      </div>
    );
  }
}

export default Loader;
