/* eslint-disable */
import loadable from '@loadable/component';

const venueNowHomepage = loadable(() => import('../pages/venueNow/venueNowHomepage'));
const CreateVenue = loadable(() => import('../pages/venueNow/management/Venues/CreateVenue'));
const EditVenue = loadable(() => import('../pages/venueNow/management/Venues/EditVenue'));
const VenueList = loadable(() => import('../pages/venueNow/management/Venues/VenueList'));
const CreateHomeCard = loadable(() => import('../pages/venueNow/management/HomeCards/CreateHomeCard'));
const HomeCardList = loadable(() => import('../pages/venueNow/management/HomeCards/HomeCardList'));
const CreateCampaign = loadable(() => import('../pages/venueNow/management/Campaigns/CreateCampaign'));
const ViewCampaigns = loadable(() => import('../pages/venueNow/management/Campaigns/ViewCampaigns'));
const CreateOffer = loadable(() => import('../pages/venueNow/management/Offers/CreateOffer'));
const EditOffer = loadable(() => import('../pages/venueNow/management/Offers/EditOffer'));
const ViewOffers = loadable(() => import('../pages/venueNow/management/Offers/ViewOffers'));


const EcoBinLocations = loadable(() => import('../pages/venueNow/analytics/EcoBin/EcoBinLocations'));
const EcoBinPackageUsage = loadable(() => import('../pages/venueNow/analytics/EcoBin/EcoBinPackageUsage'));
const EcoBinStatistics = loadable(() => import('../pages/venueNow/analytics/EcoBin/EcoBinStatistics'));
const EcoBinPeakTimes = loadable(() => import('../pages/venueNow/analytics/EcoBin/EcoBinPeakTimes'));




export const venueNowRoutes = [
  { path: '/app', component: venueNowHomepage },
  { path: '/app/management/venues/create-venue', component: CreateVenue },
  { path: '/app/management/venues/edit-venue/:locationId', component: EditVenue },
  { path: '/app/management/venues/venue-list', component: VenueList },
  { path: '/app/management/offers/create-offer', component: CreateOffer },
  { path: '/app/management/offers/view-offers', component: ViewOffers },
  { path: '/app/management/offer/edit/:id', component: EditOffer },
  { path: '/app/management/homecards/create-homecard', component: CreateHomeCard },
  { path: '/app/management/homecards/home-cards', component: HomeCardList },
  { path: '/app/management/create-campaign', component: CreateCampaign },
  { path: '/app/management/view-campaign', component: ViewCampaigns },


  { path: '/app/analytics/ecobin/locations', component: EcoBinLocations },
  { path: '/app/analytics/ecobin/package-usage', component: EcoBinPackageUsage },
  { path: '/app/analytics/ecobin/statistics', component: EcoBinStatistics },
  { path: '/app/analytics/ecobin/peak-times', component: EcoBinPeakTimes },



];
