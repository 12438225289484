/* eslint-disable */
import loadable from '@loadable/component';

const schoolHomePage = loadable(() => import('../pages/venueNowSchool/schoolHomePage'));

const NumberOfVisits = loadable(() => import('../pages/venueNowSchool/analytics/ServicePeriodVisits/NumberOfVisits'));
const PackagesTaken = loadable(() => import('../pages/venueNowSchool/analytics/PackagesTaken/PackagesTaken'));
const MissingPackages = loadable(() => import('../pages/venueNowSchool/analytics/MissingPackages/MissingPackages'));

const LocationAnalytics = loadable(() => import('../pages/venueNowSchool/LocationAnalytics/LocationAnalytics'));

const EcoBinStatPage = loadable(() => import('../pages/venueNowSchool/LocationAnalytics/LocationStatPages/EcoBinStatPage'));
const KioskStatPage = loadable(() => import('../pages/venueNowSchool/LocationAnalytics/LocationStatPages/KioskStatPage'));
const DoorEntryStatPage = loadable(() => import('../pages/venueNowSchool/LocationAnalytics/LocationStatPages/DoorEntryStatPage'));





export const vNowSchoolRoutes = [
  { path: '/app', component: schoolHomePage },

  { path: '/app/analytics/venuenow/number-of-visits', component: NumberOfVisits },
  { path: '/app/analytics/venuenow/packages-taken', component: PackagesTaken },
  { path: '/app/analytics/venuenow/missing-packages', component: MissingPackages },

  { path: '/app/analytics/location-analytics', component: LocationAnalytics },

  { path: '/app/analytics/location-analytics/ecobin-stats', component: EcoBinStatPage },
  { path: '/app/analytics/location-analytics/kiosk-stats', component: KioskStatPage },
  { path: '/app/analytics/location-analytics/door-entry-stats', component: DoorEntryStatPage },





];
