/* eslint-disable linebreak-style */
let DashboardAPI;
const env = process.env.TARGET_ENV;
if (env === 'development') {
  // DashboardAPI = 'https://api.dev.venuenow.tech';
  DashboardAPI = 'https://api.venuenow.tech';
} else if (env === 'staging') {
  DashboardAPI = 'https://portal.staging.2132qsr.com';
} else if (env === 'production') {
  DashboardAPI = 'https://api.venuenow.tech';
} else if (env === 'local') {
  DashboardAPI = 'http://localhost:3000';
}

const config = {
  PACKAGE: '20.127.0-qsr-d',
  KEYS: {
    mapbox:
      'pk.eyJ1IjoiY2xvc2Vjb21tcyIsImEiOiJjajFuYWwwYXkwMDE0MzJvdHFpenU0dGV2In0.N_YdpyhxEKuSPu80uYgTYw',
    kepler:
      'pk.eyJ1IjoiMjEzMiIsImEiOiJja3NpenprcXkwZ3ZkMnBsNHBjcnhpNGdxIn0.BUQo6_52HZZE46gbLD6Nbg',
  },
  API: {
    dashboard: {
      baseUrl: DashboardAPI,
      auth: {
        login: `${DashboardAPI}/auth/dash`,
        refresh: `${DashboardAPI}/auth/dash/refresh-token`,
        category: `${DashboardAPI}/poi/categories/client`,
        me: `${DashboardAPI}/me`,
        userTypes: `${DashboardAPI}/system-user/roles`,
      },
      stores: {
        getClientStores: `${DashboardAPI}/stores`,
        getStore: `${DashboardAPI}/store`,
        createClientStore: `${DashboardAPI}/store`,
        editClientStore: `${DashboardAPI}/store`,
        toggleActiveClientStore: `${DashboardAPI}/store/updatestatus`,
        storesBySubgroup: `${DashboardAPI}/subgroups/allstores`,
      },
      locations: {
        clientStores: `${DashboardAPI}/locations/all`,
        competitorStores: `${DashboardAPI}/client/competitor/stores`,

        client: `${DashboardAPI}/locations/all`,
        find: `${DashboardAPI}/locations/getlocation`,
        select: `${DashboardAPI}/location/%ID%`,
        post: `${DashboardAPI}/location`,
        toggleStore: `${DashboardAPI}/locations/updatestatus`,
        toggleGroups: `${DashboardAPI}/groups/updatestatus`,
        toggleSubgroup: `${DashboardAPI}/subgroups/updatestatus`,
        openClose: `${DashboardAPI}/store/openingclosing/%ID%`,
        unpair: `${DashboardAPI}/unpair/location/%LOCATION_ID%/beacon/%BEACON_ID%`,
        activeGroups: `${DashboardAPI}/groups`,
        activeSubgroups: `${DashboardAPI}/group/%ID%/subgroups`,
        groups: `${DashboardAPI}/all/groups`,
        subgroups: `${DashboardAPI}/groups/allsubgroups`,
        storesBySubgroup: `${DashboardAPI}/subgroups/allstores`,
        editSubgroup: `${DashboardAPI}/group/subgroup`,
        addSubgroup: `${DashboardAPI}/group/subgroup`,
        findGroup: `${DashboardAPI}/group`,
        findSubgroup: `${DashboardAPI}/subgroup/%ID%`,
        addGroup: `${DashboardAPI}/group`,
        countries: `${DashboardAPI}/countries`,
        timezones: `${DashboardAPI}/timezones`,
        fromGroups: `${DashboardAPI}/report/getstoresforgroup`,
        competitors: `${DashboardAPI}/client/competitor/stores`,
        usersInArea: `${DashboardAPI}/usersInProximity`,
      },
      feedback: {
        get: `${DashboardAPI}/feedback`,
        reply: `${DashboardAPI}/feedback/%ID%/reply`,
        delete: `${DashboardAPI}/feedback/%ID%`,
      },
      analytics: {
        impressions: {
          combined: `${DashboardAPI}/appPageImpression/combined?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
          ios: `${DashboardAPI}/appPageImpression/ios?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
          android: `${DashboardAPI}/appPageImpression/android?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
        },
        beacon: {
          campaigns: `${DashboardAPI}/beacon/campaignallstores/%DATE_FROM%/%DATE_TO%`,
          offerList: `${DashboardAPI}/beacon/clientoffers`,
          offer: `${DashboardAPI}/beacon/offerreportsbytype/%ID%/%DATE_FROM%/%DATE_TO%`,
        },
        competitors: {
          dwellByDay: `${DashboardAPI}/poi/reports/place`, // /poi/reports/place/:id/dwell?date_from=:date_from&date_to=:date_to
          store: {
            footfall: `${DashboardAPI}/poi/reports/place/location/%ID%/footfall?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
            dwellByDay: `${DashboardAPI}/poi/reports/place/location`, // /poi/reports/place/location/:id/dwell/?date_from=:date_from&date_to=:date_to
          },

          list: `${DashboardAPI}/poi/categories/places`,
          client: `${DashboardAPI}/poi/reports/competitor/clientsummary?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
          footfall: `${DashboardAPI}/poi/reports/place/%ID%/footfall?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
          summary: `${DashboardAPI}/poi/reports/competitor/competitorsummary?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
        },
        poi: {
          dayDwellByCategory: `${DashboardAPI}/poi/reports/category`, // /poi/reports/category/:id/dwell/

          footfall: `${DashboardAPI}/poi/reports/place/location/%ID%/footfall?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
          places: `${DashboardAPI}/poi/categories/places`,
          visits: `${DashboardAPI}/poi/reports/category/stats/total_visits/%ORDER%/%LIMIT%?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
          dwell: `${DashboardAPI}/poi/reports/category/stats/average_dwell_time/%ORDER%/%LIMIT%?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
          dwellByDay: `${DashboardAPI}/poi/reports/category/%ID%/dwell/%DAY%?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
        },
        visitsInstalls: `${DashboardAPI}/report/visitors?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
        devicesByBrand: `${DashboardAPI}/devices/brand?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
        usageByDay: `${DashboardAPI}/report/pageimpressions?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
        installsByDay: `${DashboardAPI}/report/visitors?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
        acquisition: `${DashboardAPI}/useracquisitionattrition?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
        kpi: `${DashboardAPI}/report/overview/essentialkpi?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
        // poi: `${DashboardAPI}/poitypes?poi_type_count=0&date_from=%DATE_FROM%&date_to=%DATE_TO%`,
        heatmap: `${DashboardAPI}/devicelocation?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
      },
      campaign: {
        sendInstant: `${DashboardAPI}/offer/instant`,

        post: `${DashboardAPI}/campaigns`,
        timezones: `${DashboardAPI}/campaigns/tz/timezones?country=%COUNTRY%`,
        timezonesByCountry: `${DashboardAPI}/campaigns/tz/timezones`,
        countries: `${DashboardAPI}/campaigns/tz/tzcountries`,
        conversion: `${DashboardAPI}/report/campaign/conversionreport?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
        redeemed: `${DashboardAPI}/report/campaign/allsentcountsbydate?date_from=%DATE_FROM%&date_to=%DATE_TO%&cid=0`,
        send: `${DashboardAPI}/presenceOfferSendOut`,
        fromStores: `${DashboardAPI}/report/getcampaignsforsubgroups`,
        get: `${DashboardAPI}/report/campaign/sentcountsbydate?date_from=%DATE_FROM%&date_to=%DATE_TO%&offer_type=1&cid=%ID%`,
        filteredCampaigns: `${DashboardAPI}/report/getfilteredcampaignreportdata/%DATE_FROM%/%DATE_TO%`,
        list: `${DashboardAPI}/campaigns/active/paused/completed`,
        wizard: `${DashboardAPI}/campaigns/campaign`,
        wizardCampaignStatus: `${DashboardAPI}/campaigns/campaign/updatestatus`,
        allWizardCampaigns: `${DashboardAPI}/campaigns/all`,
        deleteWizardCampaign: `${DashboardAPI}/campaigns/campaign/delete`,
        qc: {
          getAll: `${DashboardAPI}/campaigns/simple-wizard/all`,
          create: `${DashboardAPI}/campaigns/simple-wizard`,
        }
      },
      ev: {
        getChargerGroups: `${DashboardAPI}/ev/charger/groups`,
        getEvLocation: `${DashboardAPI}/ev/charger/groups`,
        editChargerGroup: `${DashboardAPI}/ev/charger/groups`,
        createChargerGroup: `${DashboardAPI}/ev/charger/groups`,
        evChargers: `${DashboardAPI}/ev/chargers`,

      },
      ecobin: {
        createEcoLocation: `${DashboardAPI}/venue`,
        getEcoLocation: `${DashboardAPI}/venue`,
        editEcoLocation: `${DashboardAPI}/venue`,
        toggleActiveEcoLocation: `${DashboardAPI}/venue/update-status`,
        getEcoLocations: `${DashboardAPI}/venue/all`,
        imageUpload: `${DashboardAPI}/upload/image`,
        imageUploadSquare: `${DashboardAPI}/upload/image?squared=true`,
        createEcoCampaign: `${DashboardAPI}/campaigns/simple-wizard`,
        toggleAvctiveEcoCampaignStatus: `${DashboardAPI}/campaigns/campaign/updatestatus`,
        getAllVenueGroups: `${DashboardAPI}/venue-group/all`,
        createOffer: `${DashboardAPI}/offer`,
        getVenueNowOffers: `${DashboardAPI}/offer/all`,
        getVenueNowOffer: `${DashboardAPI}/offer`,
        toggleActiveVenueNowOffer: `${DashboardAPI}/offer/update-status`,
      },
      westminster: {
        // totalUserVisits: `${DashboardAPI}/analytics/venue-user-visits/1?dateFrom=2022-01-01&dateTo=2050-01-01`,
        returnedContainers: `${DashboardAPI}analytics/containers-returned/1`,
        dailyLoss: `${DashboardAPI}/analytics/packages-loss/1`,
        avgContainersPerCust: `${DashboardAPI}/analytics/containers-per-customer/1?dateFrom=%DATE_FROM%&dateTo=%DATE_TO%`,
        avgContainerRtn: `${DashboardAPI}/analytics/containers-return-time/1?dateFrom=%DATE_FROM%&dateTo=%DATE_TO%`,
        customersPerMin: `${DashboardAPI}/analytics/meals-per-minute/1?dateFrom=%DATE_FROM%&dateTo=%DATE_TO%`,
        totalServiceVisits: `${DashboardAPI}/analytics/venue-period-visits/1?dateFrom=%DATE_FROM%&dateTo=%DATE_TO%`,
        containersTakenType: `${DashboardAPI}/analytics/containers-taken-type/1?dateFrom=%DATE_FROM%&dateTo=%DATE_TO%`,
        peakContainerUse: `${DashboardAPI}/analytics/peak-containers/1?dateFrom=%DATE_FROM%&dateTo=%DATE_TO%`,
        availableContainers: `${DashboardAPI}/analytics/containers-available/1`,
        unAvailableContainers: `${DashboardAPI}/analytics/containers-unavailable/1`,
        totalCo2Saved: `${DashboardAPI}/analytics/carbon-value-saved/1?dateFrom=2022-01-01&dateTo=2030-12-30`,
        serviceVisits: `${DashboardAPI}/analytics/venue-period-visits/1?dateFrom=%DATE_FROM%&dateTo=%DATE_TO%`,
        packagesTaken: `${DashboardAPI}/analytics/containers-taken/1?dateFrom=%DATE_FROM%&dateTo=%DATE_TO%`,
        packagesReturned: `${DashboardAPI}/analytics/containers-returned/1`,

        ecoBinContainersIn: `${DashboardAPI}/analytics/containers-in-per-location?dateFrom=%DATE_FROM%&dateTo=%DATE_TO%`,
        kioskContainersTaken: `${DashboardAPI}/analytics/containers-out-per-location?dateFrom=%DATE_FROM%&dateTo=%DATE_TO%`,
        doorEntryStats: `${DashboardAPI}/analytics/entries-per-location?dateFrom=%DATE_FROM%&dateTo=%DATE_TO%`,

        getKioskStats: `${DashboardAPI}/analytics/containers-out-per-location/details/:kioskId?dateFrom=%DATE_FROM%&dateTo=%DATE_TO%`,
        getEcobinStats: `${DashboardAPI}/analytics/containers-in-per-location/details/:ecobinCustomId?dateFrom=%DATE_FROM%&dateTo=%DATE_TO%`,
        getMissingPackages: `${DashboardAPI}/analytics/containers-not-returned`,
        getDoorEntryBaseDetails: `${DashboardAPI}/analytics/entries-per-location/details/:doorEntryName?date=%DATE%&timeInterval=%INTERVAL%`,
        getDoorEntryFullDetails: `${DashboardAPI}/analytics/entries-per-location/details/:doorEntryName?date=%DATE%&timeInterval=%INTERVAL%&timeRange=%TIME_FROM%,%TIME_TO%`,

      },
      supplier: {
        createPackageBatch: `${DashboardAPI}/packaging/item`,
        getPackageType: `${DashboardAPI}/packaging/types`,
        getVenueOrders: `${DashboardAPI}/packaging/items/%VENUE_ID%/creation-dates`,
        getVenuePackageItems: `${DashboardAPI}/packaging/item/%DATE%/%VENUE_ID%`,
      },
      homecard: {
        image: `${DashboardAPI}/homecard/image`,
        list: `${DashboardAPI}/homecards/all`,
        post: `${DashboardAPI}/homecard`,
      },
      offer: {
        getAll: `${DashboardAPI}/offers`,
        archived: `${DashboardAPI}/offers?archived=true`,
        visible: `${DashboardAPI}/offers?archived=false`,
        getOne: `${DashboardAPI}/offer`,
        active: `${DashboardAPI}/offer`, // offer/:id/active-toggle
        archive: `${DashboardAPI}/offer`, // offer/:id/archived-status

        all: `${DashboardAPI}/offer/all`,
        image: `${DashboardAPI}/offerimage`,
        post: `${DashboardAPI}/generaloffer`,
        list: {
          active: `${DashboardAPI}/offers/active`,
        },
        hidden: `${DashboardAPI}/offers/hidden`,
        current: `${DashboardAPI}/offers/all`,
        edit: `${DashboardAPI}/offer`,
        create: `${DashboardAPI}/offer`,
      },
    },
    beacon: {
      baseUrl: 'https://portal.2132qsr.com',
    },
    app: {
      baseUrl: 'https://api.2132qsr.com',
    },
  },
  THEME: {
    colors: {
      primary: '#009947',
      secondary: '#ffca08',
      success: '#66BB6A',
      info: '#01BCD4',
      infoAlt: '#948aec',
      warning: '#ffc53d',
      danger: '#941400',
      text: '#3D4051',
      gray: '#EDF0F1',
    },
    settings: {
      // Default states
      navExpanded: true,
      theme: 'dark',
    },
  },
  CAMPAIGNS: {
    'Flash Deal': {
      enabled: true,
    },
    Competitor: {
      enabled: true,
    },
    'First Visit': {
      enabled: true,
    },
    Periodic: {
      enabled: true,
    },
    Rolling: {
      enabled: true,
    },
    Visits: {
      enabled: true,
    },
    Homecard: {
      enabled: true,
    },
    TYPES: {
      competitor: 'Competitor',
      first_visit: 'First Visit',
      visits: 'Visits',
      rolling: 'Rolling',
      scheduled: 'Scheduled',
      homecard: 'Homecard',
    },
    campaignSettings: {
      PROXIMITY_OPTIONS: {
        instore: 'Venue',
        '100m': '100m',
        '200m': '200m',
        '300m': '300m',
        '400m': '400m',
        '500m': '500m',
      },
      RECURRING_MILESTONE: {
        recurring: 'Recurring',
        milestone: 'Milestone'
      },
      CAMPAIGN_DURATION: {
        campaign_duration: 'Campaign Duration',
        all_time: 'All Time',
      },
      PUSH_ON_FIRST_VISIT: {
        0: 'No',
        1: 'Yes',
      },
    },
    offerSettings: {
      TIME_FRAME: {
        minutes: 'Minute(s)',
        hours: 'Hour(s)',
        days: 'Day(s)',
        weeks: 'Week(s)',
        months: 'Month(s)',
      },
    },
    target: {
      ENGAGEMENT: {
        push: 'push',
        email: 'email',
        sms: 'sms',
        social: 'social',
      },
      AGE_GROUPS: {
        all: 'all',
        under_18: 'under 18',
        '18_21': '18 - 21',
        over_21: 'over 21',
      },
      DWELL_TIME: {
        all: 'all',
        lt_10_min: '< 10 minutes',
        '10_20_min': '10 - 20 minutes',
        '20_30_min': '20 - 30 minutes',
        gt_30_min: '> 30 minutes',
      },
      WEEKLY_SPEND: {
        all: 'all',
        lt_10_dollars: '< $10',
        '11_20_dollars': '$11 - $20',
        '21_30_dollars': '$21 - $30',
        gt_31_dollars: '> $31',
      }
    }
  },
  LOGOS: [
    'Applebees.png',
    'Arbys.png',
    'Board-and-Brew.png',
    'Boston-Market.png',
    'Boudin-Bakery.png',
    'Bronx-Sandwich-Company.png',
    'Bruxie.png',
    'Burger-King.png',
    'BurgerFi.png',
    'Beef-O-Bradys.png',
    'Carls-Junior.png',
    'Chick-fil-A.png',
    'Chipotle.png',
    'Corner-Bakery.png',
    'CloseComms-Demo.png',
    'CloseComms.png',
    'Dairy-Queen.png',
    'Del-Taco.png',
    'Dennys.png',
    'Dominos.png',
    'Dunkin-Donuts.png',
    'Earl-of-Sandwich.png',
    'Firehouse-Subs.png',
    'Five-Guys.png',
    'In-&-Out.png',
    'Jack-in-the-Box.png',
    'Jersey-Mikes.png',
    'Jimmy-Johns.png',
    'KFC.png',
    'McDonalds.png',
    'Moes-Southwest-Grill.png',
    'Nandos.png',
    'Nathans-Famous.png',
    'Panda-Express.png',
    'Panera-Bread.png',
    'Panini-Kabob-Grill.png',
    'Papa-Johns.png',
    'Phillys-Best.png',
    'Piadina.png',
    'Pizza-Hut.png',
    'Popeyes.png',
    'Portillos.png',
    'Quiznos.png',
    'Raising-Canes.png',
    'Sbarro.png',
    'Schlotzskys.png',
    'Shake-Shack.png',
    'Smash-Burger.png',
    'Sonic.png',
    'Starbucks.png',
    'Subway.png',
    'Taco-Bell.png',
    'Tesco-Test.png',
    'The-Hat.png',
    'The-Melt.png',
    'Tim-Hortons.png',
    'Togos.png',
    'Wendys.png',
    'Which-Which.png',
    'Wing-Shop.png',
  ],
  LOCALES: [
    {
      name: 'English',
      iso: 'en',
      locale: 'en_GB',
      default: true,
      flag: 'gb',
    },
    {
      name: 'Hindi',
      iso: 'hi',
      locale: 'hi_IN',
      flag: 'in',
    },
  ],
  GRAPH: {
    line: {
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        line: {
          fill: false,
          // tension: 0
        },
      },
      title: {
        display: false,
      },
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x:
          {
            scaleLabel: {
              display: false,
            },
            gridLines: {
              display: true,
            },
          },
        y:
          {
            display: true,
            type: 'logarithmic',
            scaleLabel: {
              display: false,
            },
            ticks: {
              display: false,
              beginAtZero: true,
              mirror: false,
              suggestedMin: 0,
            },
            gridLines: {
              display: true,
            },
          },
      },
    },
    bar: {
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 20,
          bottom: 0,
        },
      },
      scales: {
        x:
          {
            scaleLabel: {
              display: false,
            },
            gridLines: {
              display: false,
            },
          },
        y:
          {
            display: true,
            scaleLabel: {
              display: false,
            },
            ticks: {
              beginAtZero: true,
              mirror: false,
              suggestedMin: 0,
            },
            gridLines: {
              display: false,
            },
          },
      },
      responsive: true,
      title: {
        display: false,
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    },
    pie: {
      cutoutPercentage: 65,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
    },
    wordcloud: {
      colors: [
        '#1f77b4',
        '#ff7f0e',
        '#2ca02c',
        '#d62728',
        '#9467bd',
        '#8c564b',
      ],
      enableTooltip: true,
      deterministic: false,
      fontFamily: 'arial',
      fontSizes: [5, 60],
      fontStyle: 'normal',
      fontWeight: 'normal',
      padding: 4,
      rotations: 0,
      scale: 'sqrt',
      spiral: 'archimedean',
      transitionDuration: 1000,
    },
  },
  MAP: {
    center: [51.5910314, -2.9924318], // Newport
    zoom: 10,
    url:
      'https://api.mapbox.com/styles/v1/closecomms/cjui3br6i1u661fmoid0sy1sz/tiles/256/{z}/{x}/{y}@2x?access_token=%TOKEN%',
    attr:
      'Maps provided by <a target=\'_blank\' href=\'https://2132.io/\'>2132.io</a>',
  },
  _cached: {
    // Dynamic variables for system use only
    BRANDS: false,
    MAP: false,
  },
};

export default config;
