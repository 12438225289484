import { FC, useState } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Form, Button, Modal, Input, Space, Row, Col } from 'antd';
import { observer } from 'mobx-react';
import { Trans as I18N } from 'react-i18next';
import modalConfig from '../../config/modal';

import { useStores } from '../../hooks/useStores';

const LoginForm: FC = () => {
  const [posting, setPosting] = useState(false);
  const textLogin = <I18N>LOG_IN</I18N>;
  const textEmail = <I18N>EMAIL</I18N>;
  const textPassword = <I18N>PASSWORD</I18N>;
  const [form] = Form.useForm();
  const { userStore } = useStores();

  const handleSubmit = async () => {
    setPosting(true);

    const formValues = form.getFieldsValue(true);

    try {
      await userStore.authenticate(formValues);
    } catch (e) {
      Modal.error(modalConfig.error);
    } finally {
      setPosting(false);
    }
  };

  return (
    <Space size={16} direction="vertical">
      <h2 style={{
        textAlign: 'center'
      }}
      >
        {textLogin}
      </h2>

      <Form
        onFinish={handleSubmit}
        layout="vertical"
        form={form}
      >
        <Form.Item
          label={textEmail}
          name="username"
          rules={[
            {
              required: true,
              message: 'Username is required'
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={textPassword}
          name="password"
          rules={[
            {
              required: true,
              message: 'Password is required'
            }
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Row>
            <Col span={12} offset={6}>
              <Button
                type="primary"
                htmlType="submit"
                loading={posting}
                block
              >
                {textLogin}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Space>
  );
};

export default observer(LoginForm);
