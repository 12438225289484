import { makeAutoObservable } from 'mobx';

import { MainRootStore } from '..';

/**
 * @description Fetch & Store - Data for Offer Preview on Baby Dash
 */
class VenueNowStore {

  ecoBinName: string

  kioskName: string

  doorEntryName: string


  rootStore: MainRootStore;

  constructor(rootStore: MainRootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.ecoBinName = '';
    this.kioskName = '';
    this.doorEntryName = '';

 
  }

  
  setEcoBinName = (ecoBinName?: string): void => {
    if (ecoBinName) {
      this.ecoBinName = ecoBinName;
    } else {
      this.ecoBinName = '';
    }
  }

  setKioskName = (kioskName?: string): void => {
    if (kioskName) {
      this.kioskName = kioskName;
    } else {
      this.kioskName = '';
    }
  }

  setDoorEntryName = (doorEntryName?: string): void => {
    if (doorEntryName) {
      this.doorEntryName = doorEntryName;
    } else {
      this.doorEntryName = '';
    }
  }


}

export default VenueNowStore;
