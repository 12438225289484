import axios from 'axios';
import RootStore from '../../store';

const axiosInstance = axios.create({
  headers: {
    'Access-Control-Allow-Headers': 'x-access-token',
  }
});

axiosInstance.interceptors.request.use(request => {
  const { userStore } = RootStore;
  const token = userStore.accessToken;

  if (token && token.length) {
    request.headers['x-access-token'] = `${token}`;
  }

  return request;
}, error => Promise.reject(error));

axiosInstance.interceptors.response.use(response => response, async error => {
  const originalRequest = error.response.config;
  const { configStore, userStore } = RootStore;
  const NOT_AUTHORIZED = 401;
  const EXPIRED = 403;

  // if received not Authorized from refreshAccessTokenAPI
  if (
    originalRequest.url === configStore.API.dashboard.auth.refresh
    && (error.response.status === NOT_AUTHORIZED || error.response.status === EXPIRED)
  ) {
    userStore.logout();
    return Promise.reject(error);
  // else if received not Authorized and has refreshAccessToken
  }

  if (error.response.status === NOT_AUTHORIZED
    && userStore.refreshToken
  ) {
    const newAuth = await axiosInstance.post(configStore.API.dashboard.auth.refresh, {
      refreshToken: userStore.refreshToken
    });

    userStore.setAuth(newAuth.data);
    originalRequest.headers['x-access-token'] = newAuth.data.token;
    return axios(originalRequest);
  }

  return Promise.reject(error);
});

export default axiosInstance;
