import { makeAutoObservable, runInAction } from 'mobx';
import moment from 'moment';
import { Request, responseFail, configStore } from '..';

import axios from '../../utils/axios';
import sharedStrings from '../../locales/i18n/return/shared';
import formatDate from '../../utils/dateTime/formatDate';
import { transformCampaignConversionData } from '../../utils/store/campaignStoreHelpers';

/**
 * @description Fetch & Store - Campaigns and beacon associations
 */
class CampaignStore {
  constructor(rootStore) {
    makeAutoObservable(this);

    this.rootStore = rootStore;
  }

  allRedeemedCampaigns = {
    date: null,
    data: null,
    loading: true
  };

  campaignConversion = {
    top: {
      date: null,
      stats: null,
      loading: true,
    },
    bottom: {
      date: null,
      stats: null,
      loading: true,
    },
    error: null,
  };

  allStoreCampaigns = {
    loading: false,
    date: null,
    stats: null,
    ids: null,
    options: {
      selectStore: {
        options: null,
        selected: null
      },
      selectCampaign: {
        options: null,
        selected: null
      },
      selectGroup: {
        options: null,
        selected: null
      }
    }
  };

  beaconCampaigns = {
    stats: null,
    date: null,
    loading: true
  };

  campaignList = null;

  individualCampaign = {
    date: null,
    stats: null,
    selected: null,
    loading: false,
    campaign: null,
    error: null,
  };

  campaignStatus = {
    loading: false,
    error: null,
  };

  wizardCampaigns = {
    campaignList: [],
    loading: false,
    error: null,
  };

  * getRedeemedCampaigns({
    dateFrom,
    dateTo,
    refresh = false
  }) {
    if (this.allRedeemedCampaigns.data && !refresh) { return this.allRedeemedCampaigns; }
    this.allRedeemedCampaigns.loading = true;
    const url = configStore.API.dashboard.campaign.redeemed
      .replace('%DATE_FROM%', formatDate(dateFrom))
      .replace('%DATE_TO%', formatDate(dateTo));
    const res = yield Request(url);
    if (responseFail(res)) {
      throw {
        statusCode: res.statusCode || 400,
        message: sharedStrings.error_01
      };
    }
    this.allRedeemedCampaigns.date = [dateFrom, dateTo];
    this.allRedeemedCampaigns.data = res;
    this.allRedeemedCampaigns.loading = false;
    return this.allRedeemedCampaigns;
  }

  * getFilteredCampaigns({
    dateFrom,
    dateTo,
    refresh,
    ids,
    options
  }) {
    if ((this.allStoreCampaigns.stats && !refresh) || !ids) { return this.allStoreCampaigns; }
    this.allStoreCampaigns.loading = true;
    const url = configStore.API.dashboard.campaign.filteredCampaigns
      .replace('%DATE_FROM%', formatDate(dateFrom))
      .replace('%DATE_TO%', formatDate(dateTo));
    const res = yield Request(url, {
      method: 'POST',
      body: JSON.stringify(ids)
    });
    if (responseFail(res)) {
      throw {
        statusCode: res.statusCode || 400,
        message: sharedStrings.error_01
      };
    }
    this.allStoreCampaigns.date = [dateFrom, dateTo];
    this.allStoreCampaigns.stats = res;
    this.allStoreCampaigns.ids = ids;
    if (options) {
      this.allStoreCampaigns.options = options;
    }
    this.allStoreCampaigns.loading = false;
    return this.allStoreCampaigns;
  }

  * getCampaignsFromStores({ storeIds }) {
    const url = configStore.API.dashboard.campaign.fromStores;
    const res = yield Request(url, {
      method: 'POST',
      body: JSON.stringify(storeIds)
    });
    if (responseFail(res)) {
      throw {
        statusCode: res.statusCode || 400,
        message: sharedStrings.error_01
      };
    }
    return res;
  }

  getCampaignConversion = async (dateFrom, dateTo, type = 'top') => {
    const NUMBER_OF_CAMPAIGNS_TO_SHOW = 3;
    this.campaignConversion.error = null;

    this.campaignConversion[`${type}`].loading = true;

    const url = this.rootStore.configStore.API.dashboard.campaign.conversion
      .replace('%DATE_FROM%', formatDate(dateFrom))
      .replace('%DATE_TO%', formatDate(dateTo));

    try {
      const response = await axios.get(url);
      runInAction(() => {
        const data = transformCampaignConversionData(response.data);

        this.campaignConversion[`${type}`].date = [dateFrom, dateTo];

        if (type === 'top') {
          this.campaignConversion[`${type}`].stats = data
            .sort((x, y) => y.conversion - x.conversion)
            .filter((c, i) => i < NUMBER_OF_CAMPAIGNS_TO_SHOW);
        } else {
          this.campaignConversion[`${type}`].stats = data
            .sort((x, y) => x.conversion - y.conversion)
            .filter((c, i) => i < NUMBER_OF_CAMPAIGNS_TO_SHOW);
        }
        this.campaignConversion[`${type}`].loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.campaignConversion.top.loading = false;
        this.campaignConversion.bottom.loading = false;
        const statusCode = error.response.data.statusCode;
        const message = error.response.data.message;
        this.campaignConversion.error = {
          statusCode, message,
        };

        throw new Error(this.campaignConversion.error);
      });
    }
  };

  * getBeaconCampaigns({
    dateFrom,
    dateTo,
    refresh = false
  }) {
    if (this.beaconCampaigns.stats && !refresh) return this.beaconCampaigns;
    this.beaconCampaigns.loading = true;
    const url = configStore.API.dashboard.analytics.beacon.campaigns
      .replace('%DATE_FROM%', formatDate(dateFrom))
      .replace('%DATE_TO%', formatDate(dateTo));
    const res = yield Request(url);
    if (responseFail(res)) {
      throw {
        statusCode: res.statusCode || 400,
        message: sharedStrings.error_01
      };
    }
    this.beaconCampaigns.stats = res;
    this.beaconCampaigns.date = [dateFrom, dateTo];
    this.beaconCampaigns.loading = false;
    return this.beaconCampaigns;
  }

  * getCampaignList({ refresh = false } = {
  }) {
    if (this.campaignsList && !refresh) return this.campaignsList;
    const url = configStore.API.dashboard.campaign.list;
    const res = yield Request(url);
    if (responseFail(res)) {
      throw {
        statusCode: res.statusCode || 400,
        message: sharedStrings.error_01
      };
    }
    this.campaignList = res;
    return this.campaignList;
  }

  * getOneCampaign({
    selected,
    dateFrom,
    dateTo,
    refresh = false
  }) {
    if (this.individualCampaign.stats && !refresh) { return this.individualCampaign; }
    this.individualCampaign.loading = true;
    const url = configStore.API.dashboard.campaign.get
      .replace('%ID%', selected)
      .replace('%DATE_FROM%', formatDate(dateFrom))
      .replace('%DATE_TO%', formatDate(dateTo));
    const res = yield Request(url);
    if (responseFail(res)) {
      throw {
        statusCode: res.statusCode || 400,
        message: sharedStrings.error_01
      };
    }
    this.individualCampaign.selected = selected;
    this.individualCampaign.stats = res;
    this.individualCampaign.date = [dateFrom, dateTo];
    this.individualCampaign.loading = false;

    return this.individualCampaign;
  }

  * postCampaign({ form }) {
    const url = configStore.API.dashboard.campaign.post;
    const res = yield Request(url, {
      method: 'POST',
      body: JSON.stringify(form)
    });
    if (responseFail(res)) {
      throw {
        statusCode: res.statusCode || 400,
        message: sharedStrings.error_01
      };
    }
    return res;
  }

  updateCampaignLiveStatus = async id => {
    const url = this.rootStore.configStore.API.dashboard.campaign.wizardCampaignStatus;

    try {
      this.campaignStatus.loading = true;
      this.campaignStatus.error = null;

      await axios.post(url, {
        id
      });

      runInAction(() => {
        this.campaignStatus.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.campaignStatus.loading = false;
        const statusCode = error.response.data.statusCode;
        const message = error.response.data.message;
        this.campaignStatus.error = {
          statusCode, message,
        };

        throw new ResponseError(this.campaignStatus.error);
      });
    }
  };

  getAllCampaigns = async () => {
    const url = this.rootStore.configStore.API.dashboard.campaign.allWizardCampaigns;

    try {
      this.wizardCampaigns.loading = true;
      this.wizardCampaigns.error = null;

      const { data } = await axios.get(url);

      runInAction(() => {
        this.wizardCampaigns.campaignList = data;
        this.wizardCampaigns.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.wizardCampaigns.loading = false;
        const statusCode = error.response.data.statusCode;
        const message = error.response.data.message;
        this.wizardCampaigns.error = {
          statusCode, message,
        };

        throw new ResponseError(this.wizardCampaigns.error);
      });
    }
  };

  deleteCampaign = async ids => {
    const url = this.rootStore.configStore.API.dashboard.campaign.deleteWizardCampaign;

    try {
      await axios.delete(url, {
        data: {
          ids
        }
      });
    } catch (error) {
      runInAction(() => {
        const statusCode = error.response.data.statusCode;
        const message = error.response.data.message;
        throw new ResponseError({
          statusCode, message
        });
      });
    }
  };

  createCampaign = async campaign => {
    const url = this.rootStore.configStore.API.dashboard.campaign.wizard;

    try {
      this.wizardCampaigns.loading = true;

      const { data } = await axios.post(url, campaign);

      runInAction(() => {
        this.wizardCampaigns.loading = false;
        const { campaignId } = data;
        const campaign = {
          ...campaign, id: campaignId
        };
        this.individualCampaign.campaign = campaign;
        this.wizardCampaigns.campaignList = [...this.wizardCampaigns.campaignList, campaign, ];
      });
    } catch (error) {
      runInAction(() => {
        this.wizardCampaigns.loading = false;
        handleError(error);
      });
    }
  };

  updateCampaign = async campaign => {
    const url = this.rootStore.configStore.API.dashboard.campaign.wizard;

    try {
      this.individualCampaign.loading = true;

      await axios.put(url, campaign);

      runInAction(() => {
        this.individualCampaign.loading = false;
        this.wizardCampaigns.campaignList = this.wizardCampaigns.campaignList.map(item => {
          if (item.id === campaign.id) {
            return campaign;
          }

          return item;
        });
      });
    } catch (error) {
      runInAction(() => {
        this.individualCampaign.loading = false;
        handleError(error);
      });
    }
  };

  getCampaignById = async id => {
    const url = this.rootStore.configStore.API.dashboard.campaign.wizard;

    try {
      this.individualCampaign.loading = true;
      const { data } = await axios.get(`${url}/${id}`);

      runInAction(() => {
        const campaign = data;

        if (data.settings.dateRange) {
          campaign.settings.dateRange = [moment(data.settings.dateRange[0], 'YYYY-MM-DD'), moment(data.settings.dateRange[1], 'YYYY-MM-DD')];
        }

        if (data.distributionTime) {
          campaign.distributionTime = moment(data.distributionTime);
        }

        this.individualCampaign.campaign = campaign;
        this.individualCampaign.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.individualCampaign.loading = false;
        const statusCode = error.response.data.statusCode;
        const message = error.response.data.message;
        this.individualCampaign.error = {
          statusCode, message,
        };
        throw new ResponseError(this.individualCampaign.error);
      });
    }
  };
}

export default CampaignStore;
