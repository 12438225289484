import { FC } from 'react';
import { observer } from 'mobx-react';
import { Menu } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';

import { useStores } from '../../hooks/useStores';

// import ThemePicker from './ThemeChanger';
// import LanguagePicker from './LanguagePicker';
import i18n from '../../i18n';

const userName = localStorage.getItem('cc_username');

const UserDropdown: FC = () => {
  const { userStore } = useStores();

  return (
    <Menu id="user-dropdown">
      <Menu.Item key="user">
        {userName}
      </Menu.Item>

      {/* <Menu.Divider /> */}

      {/* <ThemePicker /> */}

      {/* <LanguagePicker /> */}

      {/* <Menu.Divider /> */}

      <Menu.Item key="logout" onClick={userStore.logout}>
        <span className="ml-1">
          <LogoutOutlined />
        </span>
        <span>{i18n.t('LOGOUT')}</span>
      </Menu.Item>
    </Menu>
  );
};

export default observer(UserDropdown);
