import { makeAutoObservable } from 'mobx';
import { MainRootStore } from '../index';

export default class AuthStore {
  rootStore: MainRootStore;

  constructor(rootStore: MainRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  setAccessToken = (accessToken: string) => {
    localStorage.setItem('access_token', accessToken);
  }

  setRefreshToken = (refreshToken: string) => {
    localStorage.setItem('refresh_token', refreshToken);
  }

  getAccessToken = () => localStorage.getItem('access_token');

  getRefreshToken = () => localStorage.getItem('refresh_token');

  clearTokens = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  }
}
