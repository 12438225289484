import React from 'react';
import ResetPassword from '../../components/Forms/ResetPasswordForm';

const Page = () => (
  <section className="form-card-page form-card row no-gutters">
    <div className="form-card__body col-lg-6 d-flex align-items-center">
      <div className="box box-default">
        <div className="box-body">
          <ResetPassword />
        </div>
      </div>
    </div>
  </section>
);

export default Page;
