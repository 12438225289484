import { FC } from 'react';
import { observer } from 'mobx-react';
import { Dropdown, Col, Row, Space, Button } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';

import { useStores } from '../../../hooks/useStores';

import UserDropdown from '../UserDropdown';
import { CompanyIcon } from '../CompanyIcons';

import styles from './Header.module.scss';

const Header: FC = () => {
  const { interfaceStore, userStore } = useStores();

  return (
    <Row>
      <Col span={8}>
        <Space size={24} align="center">
          <Button
            type="ghost"
            onClick={interfaceStore.toggleNav}
            onKeyUp={interfaceStore.toggleNav}
            icon={!interfaceStore.navActive
              ? <MenuUnfoldOutlined />
              : <MenuFoldOutlined />}
          />

          <NavLink to="/app" className={styles.header_iconLink}>
            {userStore.client && !Number.isNaN(userStore.client.placeId) ? (
              <CompanyIcon
                size="lg"
                companyId={userStore.client.placeId}
              />
            ) : null}
          </NavLink>
        </Space>
      </Col>

      <Col span={8} />

      <Col span={8}>
        <Row justify="end">
          <Col>
            <Dropdown
              overlay={<UserDropdown />}
              trigger={['click']}
              placement="bottomRight"
            >
              <Button
                type="ghost"
                icon={<UserOutlined />}
              />
            </Dropdown>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default observer(Header);
