import React, {Component} from 'react';
import ReactPaginate from 'react-paginate';

export class PageChanger extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={`w-100 d-block ${this.props.className || ''} py-5`}>
        <ReactPaginate
          {...this.props}
          previousLabel="<"
          nextLabel=">"
          breakLabel="..."
          pageCount={this.props.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.props.onPageChange}
          subContainerClassName="pages pagination"
          activeClassName="active"
          breakLinkClassName="page-link"
          containerClassName="pagination float-right"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
        />
      </div>
    );
  }
}

export default PageChanger;
