import { FC } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Menu } from 'antd';
import { ThunderboltOutlined } from '@ant-design/icons';

const ManageVenueSubMenu: FC = props => (
  <Menu.SubMenu
    title={(
      <span>
        <ThunderboltOutlined />
        <span>
          Offers
        </span>
      </span>
    )}
    {...props}
  >
    <Menu.Item key="/app/management/offers/create-offer">
      <Link to="/app/management/offers/create-offer">
        <span className="nav-text">
          Create Offer
        </span>
      </Link>
    </Menu.Item>
    <Menu.Item key="/app/management/offers/view-offers">
      <Link to="/app/management/offers/view-offers">
        <span className="nav-text">
          View Offers
        </span>
      </Link>
    </Menu.Item>

  </Menu.SubMenu>
);

export default observer(ManageVenueSubMenu);
