import { useEffect, FC } from 'react';
import { observer } from 'mobx-react';
import { Router,
  Switch,
  Route,
  Redirect } from 'react-router-dom';

import { useStores } from './hooks/useStores';

import Login from './pages/core/Login';
import ResetPassword from './pages/core/ResetPassword';
import Loader from './components/Blocks/LoaderFull';
import LayoutContainer from './layouts/LayoutContainer/LayoutContainer';
import RouteSwitch from './routes/RouteSwitch/RouteSwitch';
import Styleguide from './pages/core/Styleguide/Styleguide';

const App: FC = () => {
  const { userStore, routerStore, interfaceStore, configStore } = useStores();
  const { hasSession } = userStore;
  const { history: { location: { pathname } }, push } = routerStore;

  useEffect(() => {
    interfaceStore.setTheme();

    if (hasSession && !userStore.client) {
      userStore.me();
    }
  }, [
    hasSession,
    userStore,
    interfaceStore
  ]);

  useEffect(() => {
    if (userStore.client) {
      configStore.getCompetitors({
        client: userStore.client
      });

      configStore.setLocation();
    }
  }, [userStore.client, configStore]);

  useEffect(() => {
    if (pathname === '/') {
      push(!hasSession ? '/login' : '/app');
    }
  }, [
    pathname,
    hasSession,
    push
  ]);

  const LoginGuard = hasSession
    ? <Redirect to="/app" />
    : <Login />;

  if (configStore.loading || userStore.loading || (hasSession && !userStore.user?.roleId)) {
    return <Loader theme={interfaceStore.theme} />;
  }

  return (
    <Router history={routerStore.history}>
      <LayoutContainer isLoggedIn={!!hasSession}>
        <Switch>
          <Route path="/login" exact component={() => LoginGuard} />
          <Route path="/user/reset-password" exact component={ResetPassword} />
          <Route path="/styleguide" exact component={Styleguide} />

          <RouteSwitch />

          <Route component={() => <Redirect to="/app" />} />
        </Switch>
      </LayoutContainer>
    </Router>
  );
};

export default observer(App);
