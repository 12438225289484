const i18n = {
  en_GB: {
    alt_phone_no: 'Alternative Phone Number',
    alt_phone_no_placeholder: 'Please enter an alternative phone number.',
    camera: 'Camera',
    cameras: 'Cameras',
    cancel: 'Cancel',
    cancel_message: 'Are you sure you want to cancel this operation?',
    confirm: 'Confirm',
    delete: 'Delete',
    delete_error_02:
      'This item can\'t be deleted because it has other items associated with it.',
    delete_message: 'Are you sure you want to delete this item?',
    delete_success: 'You have deleted this item.',
    edit: 'Edit',
    error_01:
      'Sorry, I couldn\'t process this request because an error occurred.',
    error_02: 'This piece of feedback has already been replied to.',
    unpair_message: 'Are you sure you want to unpair these items?',
    email_address: 'Email',
    email_address_placeholder: 'Please enter an email address.',
    error: 'Error',
    error_message:
      'Sorry, I couldn\'t delete the item because an unknown error occurred.',
    error_message_form_default:
      'Sorry, I couldn\'t process your form because an unknown error occurred.',
    error_message_form_email:
      'Sorry, I couldn\'t process your form because that email already exists in our database.',
    error_message_live_map: 'No users in map viewport',
    first_name: 'First Name',
    first_name_placeholder: 'Please enter a first name.',
    full_name: 'Full Name',
    home: 'Home',
    id: 'ID',
    id_custom: 'Custom ID',
    id_custom_placeholder: 'Please enter a custom identifier.',
    id_device: 'Device ID',
    id_device_example: 'Device ID i.e. INEW-000000-ABCDE',
    id_device_placeholder: 'Please enter the device identifier.',
    id_external: 'External ID',
    id_full: 'Identifier',
    id_log: 'Log ID',
    info: 'Information',
    last_name: 'Last Name',
    last_name_placeholder: 'Please enter a last name.',
    name: 'Name',
    no: 'No',
    non_user: 'Non-User',
    non_user_select: 'Select a non-user',
    non_users: 'Non-Users',
    no_data: 'No data found.',
    ok: 'Ok',
    password: 'Password',
    password_placeholder: 'Please enter your password',
    phone_no: 'Phone Number',
    phone_no_placeholder: 'Please enter a phone number.',
    please_enter: 'Please enter a',
    please_select: 'Please select a',
    reset: 'Reset',
    role: 'Role',
    role_placeholder: 'Please select a role.',
    save: 'Save',
    save_error:
      'Sorry, I couldn\'t save your changes because an unknown error occurred.',
    save_message: 'Are you sure you want to save these changes?',
    save_success: 'Your request was successful.',
    search: 'Search',
    select: 'Select',
    select_item: 'Select an item',
    select_placeholder: 'Please select an item',
    submit: 'Submit',
    success: 'Success',
    success_message_form: 'Your form submission has been processed.',
    to_begin: 'To begin please select an item using the dropdown above.',
    users: 'Users',
    user_id: 'User ID',
    view: 'View',
    warning: 'Warning',
    yes: 'Yes',
    assign: 'Assign',
    unassign: 'Unassign',
    assign_unassign: 'Assign/Unassign',
    unassign_modal_title: 'Unassign',
    unassign_modal_message: 'Are you sure you want to unassign this camera?',
    assign_modal_title: 'Assign',
    change: 'Change',
    update: 'Update',
    online: 'Online',
    offline: 'Offline',
    streaming: 'Streaming',
    enabled: 'Enabled',
    disabled: 'Disabled',
    now: 'Now',
    loading: 'Loading',
    type: 'Type',
    type_placeholder: 'Please select a type',
    person: 'Person',
    type_select: 'Select a type',
    person_select: 'Select a person',
    access_time_date: 'Access Date & Time',
    logs: 'Access Logs',
    refresh: 'Refresh',
    reload: 'Reload',
    download: 'Download',
    export: 'Export',
    version: 'Version',
    about: 'About',
    dashboard_by: 'Designed and developed by',
    status: 'Status',
    user_field: 'User',
    user_field_select: 'Select a user',
    assigned_to: 'Assigned to',
    unassigned: 'Unassigned',
    system_users: 'System Users',
    app_users: 'App Users',
    feedback: 'Feedback',
    rating: 'Rating',
    user_details: 'User',
    date_time: 'Date & Time',
    reply: 'Reply',
    indoor: 'Indoor',
    outdoor: 'Outdoor',
    success_01: 'Your request was successfully processed.',
    check_all: 'Check All',
    live_map: 'Live Map',
    every_x_days: 'Every 𝒙 Days',
    in_app_title: 'Inbox Title',
    in_app_title_placeholder: 'Please enter an Inbox Title',
    in_app_body: 'Inbox Body',
    in_app_body_placeholder: 'Please enter an Inbox Body',
    push_notification_title: ' Notification Title',
    push_notification_title_placeholder:
      'Please enter an Push Notification Title',
    push_notification_body: ' Notification Body',
    push_notification_body_placeholder:
      'Please enter an Push Notification Body',
    inbox_timeout: 'Inbox Timeout',
    inbox_timeout_placeholder: 'Please enter an Inbox Timeout',
    redeem_timeout: 'Redeem Timeout',
    redeem_timeout_placeholder: 'Please enter a Redeem Timeout',
    terms_and_conditions: 'Terms & Conditions',
    terms_and_conditions_placeholder: 'Please enter some Terms & Conditions',
    offer_id: 'Offer ID',
    offer_id_placeholder: 'Please select a offer',
    no_association: 'No Association',
    no_comment:
      'No Comment Found: No comment was sent by the user for this piece of feedback.',
    app_analytics: 'App',
    acquisition: 'Acquisition',
    interactions: 'Interactions',
    devices: 'Devices',
    deviceMap: 'Device Map',
    action: 'Action',
    name_placeholder: 'Please provide a name',
    description_placeholder: 'Please provide a description',
    custom_url_placeholder: 'Please provide a URL',
    terms_placeholder: 'Please provide Terms & Conditions',
    upload_placeholder: 'Please provide a image',
    preview: 'Preview',
    time_zone: 'Time Zone',
    send: 'Send',
    beacon: 'Beacon',
    pair: 'Pair',
    unpair: 'Unpair',
    upload_file: {
      error_dimensions: 'Image dimensions should be 2560 x 1280',
      error_filetype: 'You can only upload JPG, JPEG, or a PNG file',
      error_size: 'File size must be below 2MB',
      error: 'Error during upload, the file wasn\'t uploaded',
      success: 'File upload successful'
    },
    minute: {
      singular: 'Minute',
      plural: 'Minutes',
      plural_alt: 'Minute(s)',
      unit: 'min'
    },
    hour: {
      singular: 'Hour',
      plural: 'Hours',
      plural_alt: 'Hour(s)',
      unit: 'hr'
    },
    day: {
      singular: 'Day',
      plural: 'Days',
      plural_alt: 'Day(s)',
      unit: 'day'
    },
    week: {
      singular: 'Week',
      plural: 'Weeks',
      plural_alt: 'Week(s)',
      unit: 'week'
    },
    upload: {
      singular: 'Upload',
      plural: 'Uploads',
      file: 'Upload File',
      image: 'Upload Image'
    },
    group: {
      singular: 'Group',
      plural: 'Groups',
      plural_alt: 'Group(s)'
    },
    subgroup: {
      singular: 'Subgroup',
      plural: 'Subgroups',
      plural_alt: 'Subgroup(s)'
    },
    channel: {
      singular: 'Channel',
      plural: 'Channels',
      plural_alt: 'Channel(s)'
    },
    sex: 'Sex',
    age_group: {
      singular: 'Age Group',
      plural: 'Age Groups',
      plural_alt: 'Age Group(s)'
    },
    location: {
      singular: 'Location',
      plural: 'Locations',
      plural_alt: 'Location(s)'
    },
    delay: 'Delay',
    proximity_trigger: 'Proximity Trigger',
    dwell_time: 'Dwell Time',
    length: 'Length',
    distribution_time: 'Distribution Time',
    visit: {
      singular: 'Visit',
      plural: 'Visits',
      visits_required: 'Visits Required',
      send_on_first_visit: 'Send on First Visit',
      first_visit: 'First Visit'
    },
    goal: 'Goal',
    duration: 'Duration',
    trigger_type: 'Trigger Type',
    periodic: 'Periodic',
    rolling: 'Rolling',
    previous: 'Previous',
    next: 'Next',
    address_system: {
      address_line_1: 'Address Line 1',
      address_line_2: 'Address Line 2',
      address_line_3: 'Address Line 3',
      address_line_4: 'Address Line 4',
      locality: 'Town/City',
      region: 'Region',
      country: 'Country',
      post_code: 'Post Code/ZIP Code'
    },
    user: {
      login: {
        title: 'Log In',
        button: 'Log in',
        reset_link: 'Forgot your password?',
        sign_up_link: 'No account?'
      },
      sign_up: {
      },
      reset_password: {
        title: 'Reset Password',
        button: 'Send'
      }
    },
    header: {
      globe_dropdown: {
        title: 'Change language',
        english: 'English',
        spanish: 'Spanish'
      },
      user_dropdown: {
        logout: 'Log out',
        settings: 'Settings'
      }
    },
    sidebar: {
      campaigns_and_offers: 'Campaigns & Offers',
      home_card: 'Home Cards'
    },
    date_picker: {
      last_7_days: 'Last 7 Days',
      last_14_days: 'Last 14 Days',
      last_21_days: 'Last 21 Days',
      last_31_days: 'Last 31 Days',
      last_month: 'Last Month',
      last_3_months: 'Last 3 Months',
      season_1: 'N.H. Spring / S.H. Fall',
      season_2: 'N.H. Summer / S.H. Winter',
      season_3: 'N.H. Fall / S.H. Spring',
      season_4: 'N.H. Winter / S.H. Summer',
      next_3_months: 'Next 3 Months',
      next_6_months: 'Next 6 Months',
      next_9_months: 'Next 9 Months',
      next_12_months: 'Next 12 Months',
      q1: 'Jan, Feb, Mar',
      q2: 'Apr, May, Jun',
      q3: 'Jul, Aug, Sep',
      q4: 'Oct, Nov, Dec',
      first_6_months: 'First 6 Months of',
      last_6_months: 'Last 6 Months of',
      thanksgiving: 'Thanksgiving',
      thanksgiving_christmas: 'Thanksgiving + Christmas',
      christmas: 'Christmas',
      new_year: 'New Year',
      easter: 'Easter',
      halloween: 'Halloween',
      valentines: 'Valentine\'s Day',
      chineseNewYear: 'Chinese New Year',
      independenceUSA: 'Independence Day (US)'
    },
    essential_statistics: {
      title: 'Essential Statistics',
      total_visitors: 'Total Visitors (C.E.)',
      installs_connections: 'Installs to Connections (C.E.)',
      ios_installs: 'iOS Installs (C.E.)',
      android_installs: 'Android Installs (C.E.)',
      total_installs: 'Total Installs (C.E.)',
      beacon_views: 'Beacon Views (C.E.)'
    },
    campaigns_offers_terms: {
      offers_sent: 'Sent',
      offers_opened: 'Opened',
      offers_redeemed: 'Redeemed'
    },
    analytics: {
      app: {
        acquisition: {
          installs_title: 'Installs by Day',
          installs: 'App Installs',
          attrition_title: 'Acquisition & Attrition',
          push_messaging_allowed: ' Notifications Allowed',
          turned_push_messaging_off: ' Notifications Declined',
          location_services_allowed_all: 'Location Services Allowed',
          turned_location_services_off: 'Location Services Declined'
        },
        interactions: {
          title: 'App Interactions',
          usage_title: 'App Usage by Day',
          no_of_interactions: 'No. of Interactions',
          no_data: 'No Interaction Data',
          combined: 'Combined'
        },
        devices: {
          devices_title: 'Devices'
        }
      },
      location_heatmap: 'Location Heatmap'
    },
    manage: {
      word: 'Manage',
      app: {
        homecards: {
          create: 'Create Home Card',
          manage: 'Manage Home Cards'
        }
      },
      locations: {
        singular: 'Location',
        plural: 'Locations',
        sidebar: {
          groupsAndSubgroups: 'Groups & Subgroups',
          venues: 'Venues',
          createVenue: 'Create Venue',
        },
        groups: {
          manage: 'Manage Groups',
          create: 'Create Group'
        },
        subgroups: {
          manage: 'Manage Subgroups',
          create: 'Create Subgroup'
        },
        venue: {
          singular: 'Venue',
          create: 'Create Venue',
          manage: 'Manage Venues',
          data: 'Venue Data'
        }
      },
      marketplace: {
        plural: 'Marketplace',
        home: 'Home',
        reports: 'Reports',
        manageCampaigns: 'Manage Campaigns',
        createCampaign: 'Create Campaign',
      },
      offers: {
        singular: 'Offer',
        plural: 'Offers',
        manage: 'Manage Offers',
        create: 'Create Offer',
        hidden: 'Archived Offers',
        instant: 'Instant Offer',
        current: 'Current Offers',
        send: 'Send Offers'
      },
      ev: {
        singular: 'EV',
        createLocation: 'Create Location',
        locations: 'Locations',
        devices: 'Devices',
        ports: 'Ports'

      }
    },
    campaigns_overview: {
      title: 'All Campaigns Sent & Redeemed'
    },
    campaigns_individual: {
      title: 'Individual Campaign Sent & Redeemed'
    },
    campaigns: {
      singular: 'Campaign',
      plural: 'Campaigns',
      create: 'Create Campaign',
      manage: 'Manage Campaigns'
    },
    triggers: {
      singular: 'Trigger',
      plural: 'Triggers',
      create: 'Create Trigger'
    },
    app: {
      users: {
        create: {
          title: 'Create User'
        }
      }
    },
    qc: {
      createCampaign: 'Create Campaign',
      manageCampaigns: 'Manage Campaigns',
      map: 'Live Map',
    },
    evManage: {
      deviceManagement: 'Device Manager',
      managementHome: 'Home',
      reports: 'Ev Reports'
    },
    venueNow: {
      homepage: 'Home',
      offers: 'Offers',
      ecobinUsage: 'Eco Bin Usage',
      ecobinReports: 'Eco Bin Reports',
      createEcoBin: 'Create Eco Bin',
      createHomeCards: 'Create Home Card',
      homeCards: 'Home Cards'
    },
    suppliers: {
      homepage: 'Home',
      viewBatch: 'View Package Batches',
      createBatch: 'Create Package Batches',

    }
  }
};

export default i18n;
