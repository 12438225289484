import moment from 'moment';

const defaultDates = {
  today: moment(),
  yesterday: moment().subtract(1, 'day'),
  seven_days_ago: moment().subtract(7, 'day'),
  seven_days_from_now: moment().add(7, 'day'),
  fourteen_days_ago: moment().subtract(14, 'day'),
  twentyone_days_ago: moment().subtract(21, 'day'),
  thirtyone_days_ago: moment().subtract(31, 'day'),
  start_day_last_month: moment().subtract(1, 'months').startOf('month'),
  end_day_last_month: moment().subtract(1, 'months').endOf('month'),
  start_of_day_3_months: moment().subtract(3, 'months').startOf('month')
};

export default defaultDates;
