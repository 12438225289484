import {getStringParams} from '.';
import i18n from '../i18n';

const validationPrototypes = {
  isRequired: (input) => {
    if (!input) {
      const msg = i18n.t('VALIDATION_REQUIRED');
      return {
        valid: false, msg
      };
    }
    return {
      valid: true, msg: null
    };
  },

  isNumeric: (input) => {
    if (isNaN(parseInt(input))) {
      const msg = i18n.t('VALIDATION_NUMERIC');
      return {
        valid: false, msg
      };
    }
    return {
      valid: true, msg: null
    };
  },

  isMinLength: (input, maxInt) => {
    if (!(input.length > maxInt)) {
      const msg = i18n.t('VALIDATION_STR_LEN').replace('%NUM%', maxInt);
      return {
        valid: false, msg
      };
    }
    return {
      valid: true, msg: null
    };
  },

  isMaxInt: (input, maxInt, unit = '') => {
    if (input > maxInt) {
      const msg = i18n
        .t('VALIDATION_INT_MAX')
        .replace('%NUM%', maxInt)
        .replace('%UNIT%', unit);
      return {
        valid: false, msg
      };
    }
    return {
      valid: true, msg: null
    };
  }
};
export const isFieldError = ({input, rules, unit}) => {
  let fieldState = {
    valid: true, msg: null
  };
  rules.forEach((rule) => {
    let checker = fieldState;
    if (rule.includes('isMinLength') || rule.includes('isMaxInt')) {
      const params = getStringParams(rule);
      const idxSplit = rule.indexOf('(');
      const func = rule.substring(0, idxSplit);
      checker = validationPrototypes[func](input, ...params);
    } else {
      checker = validationPrototypes[rule](input);
    }
    if (!checker.valid) fieldState = checker;
  });
  return fieldState.msg;
};
