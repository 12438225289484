import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import LogIn from '../../components/Forms/LoginForm';

@inject('interfaceStore')
@observer
class Login extends Component {
  constructor(props) {
    super(props);
    this.props.interfaceStore.setTheme();
    this.state = {
      theme: this.props.interfaceStore.theme
    };
  }

  render() {
    return (
      <div
        className={`
        h-100 w-100 theme-${this.state.theme}
      `}
      >
        <section className="form-card-page form-card row no-gutters Login flex-center">
          <div className="form-card__body col-xl-3 col-lg-4 col-md-6 col-sm-9 col-10 Login__auth">
            <div className="box box-default">
              <div className="box-body">
                <LogIn />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Login;
