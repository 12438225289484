import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {inject, observer} from 'mobx-react';

@inject('configStore')
@observer
export class CompanyIcon extends Component {
  constructor(props) {
    super(props);
  }


  render() {
    const company = this.props.configStore.BRANDS[this.props.companyId];

    if (company && Object.keys(company).length) {
      const path = company.paths.bundle[this.props.size === 'lg' ? 'logo' : 'icon'];
      return <img src={path} />;
    }
    return null;
  }
}

CompanyIcon.propTypes = {
  companyId: PropTypes.number.isRequired,
  size: PropTypes.string.isRequired
};
export default CompanyIcon;
