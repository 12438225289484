/* eslint-disable */
import loadable from '@loadable/component';

const SupplierHome = loadable(() => import('../pages/supplier/homepage/SupplierHome'));
const ViewPackageBatch = loadable(() => import('../pages/supplier/management/ViewPackageBatch'));
const CreatePackageBatch = loadable(() => import('../pages/supplier/management/CreatePackageBatch'));


export const supplierRoutes = [
  { path: '/app', component: SupplierHome },
  { path: '/app/view-batch', component: ViewPackageBatch },
  { path: '/app/create-batch', component: CreatePackageBatch },
];
