import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input } from 'antd';
import {MailOutlined} from '@ant-design/icons';
import {withRouter} from 'react-router-dom';
import React, {Component} from 'react';
import sharedStrings from '../../locales/i18n/return/shared';

class ResetPassword extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.history.push('/login');
      }
    });
  };

  render() {
    const {getFieldDecorator} = this.props.form;

    const titleText = sharedStrings.user.reset_password.title;
    const emailPH = sharedStrings.email_address_placeholder;
    const buttonText = sharedStrings.user.reset_password.button;
    const cancelBtn = sharedStrings.cancel;

    return (
      <section className="form-container">
        <h2>Reset Password</h2>
        <p className="additional-info mb-4">
          Enter the email address you used when you joined and we’ll send you
          instructions to reset your password.
        </p>
        <Form onSubmit={this.handleSubmit} className="form">
          <Form.Item className="mb-3">
            {getFieldDecorator('resetpassword-email', {
              rules: [
                {
                  type: 'email',
                  message: ' '
                },
                {
                  required: true,
                  message: {
                    emailPH
                  }
                }
              ]
            })(<Input size="large" prefix={<MailOutlined />} />)}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="btn-cta">
              {buttonText}
            </Button>
            <Link className="ant-btn ant-btn-danger" to="/login">
              {cancelBtn}
            </Link>
          </Form.Item>
        </Form>
      </section>
    );
  }
}

const WrappedResetPassword = Form.create()(withRouter(ResetPassword));

export default WrappedResetPassword;
