import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {inject, observer} from 'mobx-react';
import i18n from '../../i18n';
import Spinner from '../Blocks/Spinner';

@inject('interfaceStore')
@observer
export class ConfirmModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: true
    };
  }

  componentDidMount = () => {};

  onClose = () => {
    this.setState({
      visible: false
    });
    setTimeout(() => {
      this.props.interfaceStore.hideModal();
    }, 1500);
  };

  onSubmit = async () => {
    this.setState({
      loading: true
    });
    try {
      if (this.props.interfaceStore.modal.onSubmit) {
        await this.props.interfaceStore.modal.onSubmit();
      }

      this.props.interfaceStore.successModal();
    } catch (e) {
      console.error(e);

      this.props.interfaceStore.errorModal();
    }
    this.onClose();
    this.setState({
      loading: false
    });
  };

  render() {
    const {title} = this.props.interfaceStore.modal;
    const {message} = this.props.interfaceStore.modal;
    const submit = i18n.t('SUBMIT');
    return (
      <Modal
        show={this.state.visible}
        className="pt-6 Modal ConfirmModal"
        onHide={this.onClose}
      >
        <div className="modal-header">
          <h5 className="modal-title">{title}</h5>
          <button type="button" className="close" onClick={this.onClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <span>{message}</span>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary relative"
            disabled={this.state.loading}
            onClick={this.onSubmit}
          >
            {submit}
          </button>
        </div>
      </Modal>
    );
  }
}

export default ConfirmModal;
