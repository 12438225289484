import { FC } from 'react';
import { observer } from 'mobx-react';
import { Route } from 'react-router-dom';
import { Layout } from 'antd';
// import cx from 'classnames';

import { useStores } from '../../hooks/useStores';
import { ModalBus } from '../../components/Modals/ModalBus';

import Login from '../../pages/core/Login';
import AppHeader from '../../components/Blocks/Header/Header';
import SidebarSwitch from '../../components/Sidebar/SidebarSwitch/SidebarSwitch';

interface ILayoutContainerProps {
  isLoggedIn: boolean;
}

const LayoutContainer: FC<ILayoutContainerProps> = ({ isLoggedIn, children }) => {
  const { interfaceStore } = useStores();
  const { navActive, theme } = interfaceStore;
  const { Content, Sider, Header } = Layout;

  if (!isLoggedIn) return <Route component={() => <Login />} />;

  return (
    <Layout
      className={`theme-${theme}`}
    >
      {/* TODO: Need to remove Modal Bus after all refactoring as it's redundant */}
      <ModalBus />

      <Header>
        <AppHeader />
      </Header>

      <Layout hasSider>
        <Sider
          collapsible
          collapsed={!navActive}
          collapsedWidth={64}
          width={290}
          trigger={null}
        >
          <SidebarSwitch />
        </Sider>

        <Content>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default observer(LayoutContainer);
