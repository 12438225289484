/* eslint-disable import/no-cycle */
import { createContext } from 'react';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { createBrowserHistory } from 'history';
import InterfaceStore from './interface';
import UserStore from './user';
import FeedbackStore from './feedback';
import LocationStore from './location';
import AnalyticsStore from './analytics';
import CampaignStore from './campaign';
import OfferStore from './offer';
import ConfigStore from './config';
import FormStore from './forms';
import AuthStore from './auth';
import VenueNowStore from './venunow';

const createHistory = createBrowserHistory();

export const configStore = new ConfigStore();
export const routerStore = new RouterStore();
export const history = syncHistoryWithStore(createHistory, routerStore);

export class MainRootStore {
  routerStore: RouterStore;

  configStore: ConfigStore;

  interfaceStore: InterfaceStore;

  analyticsStore: AnalyticsStore;

  userStore: UserStore;

  feedbackStore: FeedbackStore;

  locationStore: LocationStore;

  campaignStore: CampaignStore;

  offerStore: OfferStore;

  formStore: FormStore;

  authStore: AuthStore;

  venueNowStore: VenueNowStore

  constructor() {
    this.routerStore = routerStore;
    this.configStore = new ConfigStore();
    this.interfaceStore = new InterfaceStore(this);
    this.analyticsStore = new AnalyticsStore(this);
    this.userStore = new UserStore(this);
    this.feedbackStore = new FeedbackStore(this);
    this.locationStore = new LocationStore(this);
    this.campaignStore = new CampaignStore(this);
    this.offerStore = new OfferStore(this);
    this.formStore = new FormStore(this);
    this.authStore = new AuthStore(this);
    this.venueNowStore = new VenueNowStore(this);
  }
}

const RootStore = new MainRootStore();

export const StoreContext = createContext({
} as MainRootStore);

/**
 *
 * @param {string} url
 * @param {object} params
 * @description Use this method for any outside requests as it will handle
 * the re-authentication of our user if their access token expires.
 * @returns {promise}
 */
export const Request = async (url: any, params: any = {
}) => {
  const isAuth = url === RootStore.configStore.API.dashboard.auth;

  if (!params.headers) {
    params.headers = {
    };
  }

  params.headers.Accept = params.headers.Accept || 'application/json';
  params.headers['Content-Type'] = params.headers['Content-Type'] || 'application/json';
  if (params.headers['Content-Type'].includes('form')) {
    delete params.headers['Content-Type'];
  }
  params.method = params.method || 'GET';
  const req: any = {
    method: params.method,
    headers: isAuth
      ? params.headers
      : {
        ...params.headers, 'x-access-token': RootStore.userStore.hasSession
      }
  };
  if (params.body && params.method && params.method !== 'GET') {
    req.body = params.body;
  }
  const res = await fetch(url, req);
  if (res.status === 401 && RootStore.userStore.refreshToken) {
    const ref = await fetch(RootStore.configStore.API.dashboard.auth.refresh, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        refreshToken: RootStore.userStore.refreshToken
      })
    });
    const newAuth = await ref.json();
    RootStore.userStore.setAuth(newAuth);
    req.headers['x-access-token'] = newAuth.token;
    const reAttempt = await fetch(url, req);
    try {
      const json = await reAttempt.json();
      return json;
    } catch (e) {
      return {
        statusCode: reAttempt.status
      };
    }
  } else {
    try {
      const json = await res.json();
      return json;
    } catch (e) {
      return {
        statusCode: res.status
      };
    }
  }
};

/**
 *
 * @param {object} res
 * @description Recursive function that checks HTTP requests for errors.
 * API returns errors in different ways, sometimes nested, easier to check
 * for all possible types than rewrite each endpoint.
 * @returns {boolean}
 */
export const responseFail = (res: any): any => {
  const flag = (res.statusCode && ![200, 201].includes(res.statusCode))
    || (res.raw && !res.raw.affectedRows)
    || (res.error && res.error === 'Bad Request');

  if (!flag && res.response) {
    return responseFail(res.response);
  }

  return flag;
};

export default RootStore;
