import { FC } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Menu } from 'antd';
import { BankOutlined } from '@ant-design/icons';

const ManageVenueSubMenu: FC = props => (
  <Menu.SubMenu
    title={(
      <span>
        <BankOutlined />
        <span>
          Venues
        </span>
      </span>
    )}
    {...props}
  >
    <Menu.Item key="/app/management/venues/create-venue">
      <Link to="/app/management/venues/create-venue">
        <span className="nav-text">
          Create Venue
        </span>
      </Link>
    </Menu.Item>
    <Menu.Item key="/app/management/venues/venue-list">
      <Link to="/app/management/venues/venue-list">
        <span className="nav-text">
          Venue List
        </span>
      </Link>
    </Menu.Item>

  </Menu.SubMenu>
);

export default observer(ManageVenueSubMenu);
