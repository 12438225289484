import {Modal} from 'antd';
import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {range} from '../../utils';
import PageChanger from '../Blocks/PageChanger';

@inject('offerStore', 'interfaceStore')
@observer
class OfferPickerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offerList: [],
      showList: [],
      pageCount: 0,
      perPage: 4,
      offset: 0,
      visible: true
    };
  }

  componentDidMount = async () => {
    const activeList = await this.props.offerStore.getActiveOffers({
      refresh: true
    });
    this.setState({
      offerList: activeList,
      pageCount: activeList.length / this.state.perPage,
      showList: activeList.filter((x, i) => i < 4)
    });
  };

  onSelect = async (offer) => {
    this.setState({
      loading: true
    });
    try {
      if (this.props.interfaceStore.modal.onSubmit) {
        await this.props.interfaceStore.modal.onSubmit(offer);
      }
    } catch (e) {
      console.error(e);
    }

    this.setState({
      loading: false
    });
    this.onClose();
  };

  onInfo = () => {};

  onClose = () => {
    this.setState({
      visible: false
    });
    setTimeout(() => {
      this.props.interfaceStore.hideModal();

      if (this.props.interfaceStore.modal.flags === 'FROM_POI') {
        this.props.interfaceStore.showModal({
          name: 'OFFERS.POI_OFFER_CONFIRM'
        });
      }
    }, 1500);
  };

  onPageClick = (e) => {
    const endIndex = this.state.perPage * (e.selected + 1) - 1;
    const startIndex = endIndex - this.state.perPage + 1;
    const indexArr = range(startIndex, endIndex);
    const pageList = [
      this.state.offerList[indexArr[0]],
      this.state.offerList[indexArr[1]],
      this.state.offerList[indexArr[2]],
      this.state.offerList[indexArr[3]]
    ];
    this.setState({
      showList: pageList
    });
  };

  renderOffers = () => this.state.showList
    .filter((arr) => !!arr)
    .map((o, i) => (
      <div className="col-3" key={i}>
        <div className="card w-100 bg-dark">
          <img className="card-img-top" src={o.image} alt="Card image cap" />
          <div className="card-body py-2">
            <button className="btn btn-primary" onClick={this.onInfo}>
              Info
            </button>
            <button
              className="btn btn-primary float-right"
              onClick={this.onSelect.bind(this, o)}
            >
              Select
            </button>
          </div>
        </div>
      </div>
    ));

  render() {
    return (
      <Modal
        className="large-modal w-100 Modal OfferPickerModal"
        visible={this.state.visible}
        closable
        destroyOnClose
        title="Attach Offer"
        footer={null}
        onCancel={this.onClose}
      >
        <div className="row mt-3">
          {this.state.offerList ? this.renderOffers() : null}
        </div>
        <PageChanger
          pageCount={this.state.pageCount}
          onPageChange={this.onPageClick}
        />
      </Modal>
    );
  }
}

export default OfferPickerModal;
