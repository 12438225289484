import { FC } from "react";
import { observer } from "mobx-react";

import { useStores } from "../../../hooks/useStores";

import VenueNowSidebar from "../VenueNowSidebar/VenueNowSidebar";
import SupplierSidebar from "../SupplierSidebar/SupplierSidebar";
import VenueNowSchoolSidebar from "../VenueNowSchoolSidebar/VenueNowSchoolSidebar";

const SidebarSwitch: FC = () => {
  const { userStore } = useStores();
  let sidebar = null;

  switch (userStore.user?.roleId) {
    case 12:
      sidebar = <SupplierSidebar />;
      break;
    case 13:
      sidebar = <VenueNowSchoolSidebar />;
      break;
    case 14:
      sidebar = <VenueNowSidebar />;
      break;
    default:
      break;
  }

  return <>{sidebar}</>;
};

export default observer(SidebarSwitch);
