// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Card-module__card___31u3y{background:var(--color-light-grey);border-radius:3px;padding:1rem;position:relative}\n", ""]);
// Exports
exports.locals = {
	"card": "Card-module__card___31u3y"
};
module.exports = exports;
