import { FC, ReactNode } from 'react';
import { observer } from 'mobx-react';

import { useStores } from '../../hooks/useStores';

import { venueNowRoutes } from '../venueNowRoutes';
import { supplierRoutes } from '../supplierRoutes';
import { vNowSchoolRoutes } from '../vNowSchoolRoutes';
import { mapRoutes } from './RouteSwitchHelpers';

const RouteSwitch: FC = () => {
  const { userStore } = useStores();
  let routes: ReactNode[] | null = null;

  switch (userStore.user?.roleId) {
    case 12:
      routes = mapRoutes(supplierRoutes);
      break;

    case 13:
      routes = mapRoutes(vNowSchoolRoutes);
      break;

    case 14:
      routes = mapRoutes(venueNowRoutes);
      break;

    default:
      break;
  }

  return (
    <>
      {routes}
    </>
  );
};

export default observer(RouteSwitch);
