import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import {inject, observer} from 'mobx-react';
import moment from 'moment';
import i18n from '../../i18n';
import Validation from '../Blocks/Validation';
import {isFieldError} from '../../utils/validation';

@inject('interfaceStore', 'offerStore')
@observer
export class PoiOfferPoiOfferConfirmModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: true,
      isError: {
        name: false,
        offer: false
      },
      isValid: {
        name: true,
        offer: !!this.props.offerStore.poiOfferTiles.send.offer
      },
      form: {
        offer: this.props.offerStore.poiOfferTiles.send.offer,
        name:
          this.props.offerStore.poiOfferTiles.send.name
          || this.presetOfferName()
      }
    };
  }

  presetOfferName = () => {
    const today = moment().format('DD-M-YYYY');
    const offerList = this.props.offerStore.poiOfferTiles.selected.map(
      (p) => p.name
    );
    return `PoiOffer - ${offerList} (Sent ${today})`;
  };

  validateForm = () => {
    let formIsValid = true;
    const errors = {
    };
    const valids = {
    };
    Object.keys(this.state.form).forEach((key) => {
      const val = isFieldError({
        input: this.state.form[key],
        rules: ['isRequired']
      });
      valids[key] = !val;
      errors[key] = val;
      formIsValid = !val;
    });
    this.setState({
      isError: errors,
      isValid: valids
    });
    return formIsValid;
  };

  validateField = ({name, value, rules}) => {
    let val = '';
    if (!isNaN(parseInt(value)) || !isEmpty(value)) {
      val = value;
    }
    const isError = isFieldError({
      input: val, rules
    });
    console.log(name);
    this.setState({
      isError: {
        ...this.state.isError,
        [name]: isError
      },
      isValid: {
        ...this.state.isValid,
        [name]: !isError
      }
    });
    return isError;
  };

  onFormInput = ({event, rules}) => {
    event.preventDefault();
    const {value} = event.target;
    const inputName = event.target.getAttribute('name');
    this.validateField({
      name: inputName, value, rules
    });
    this.props.offerStore.setPoiOfferSend({
      name
    });
    this.setState({
      form: {
        ...this.state.form,
        [inputName]: event.target.value
      }
    });
  };

  onOfferAttach = ({event, rules}) => {
    this.props.interfaceStore.showModal({
      name: 'OFFER_PICKER',
      flags: 'FROM_POI',
      onClick: (offer) => {
        this.props.offerStore.setPoiOfferSend({
          offer
        });
      }
    });
  };

  onClose = () => {
    this.setState({
      visible: false
    });
    setTimeout(() => {
      this.props.interfaceStore.hideModal();
    }, 1500);
  };

  onSubmit = async () => {
    const isValid = this.validateForm();
    if (isValid) {
      this.setState({
        loading: true
      });
      try {
        await this.props.offerStore.poiOfferSend();
        this.onClose();
      } catch (e) {
        console.error(e);
        this.setState({
          visible: false
        });
        this.props.interfaceStore.errorModal();
      }
    }
  };

  renderInputClass = (type) => {
    let classString = 'form-control';
    if (this.state.isError[type]) classString += ' is-invalid';
    else if (!this.state.isError[type] && this.state.isValid[type]) classString = `${classString} is-valid`;
    return classString;
  };

  render() {
    const submit = i18n.t('SUBMIT');
    return (
      <Modal
        show={this.state.visible}
        className="pt-6 Modal PoiOfferConfirmModal"
        onHide={this.onClose}
      >
        <div className="modal-header">
          <h5 className="modal-title">Confirm Offer</h5>
          <button type="button" className="close" onClick={this.onClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="form-group">
            <label required>
              {' '}
              {i18n.t('OFFER_NAME')}
              {' '}
            </label>
            <input
              type="text"
              name="name"
              className={this.renderInputClass('name')}
              value={this.state.form.name}
              onChange={(e) => this.onFormInput({
                event: e,
                rules: ['isMinLength(4)', 'isRequired']
              })}
            />
            <Validation isError={this.state.isError.name}>
              {this.state.isError.name}
            </Validation>
          </div>
          <div className="form-group">
            <label required>{i18n.t('ATTACH_OFFER')}</label>
            <div className="input-group">
              <div className="input-group-prepend">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={(e) => {
                    this.onOfferAttach({
                      event: e, rules: ['isRequired']
                    });
                  }}
                >
                  {i18n.t('BROWSE')}
                </button>
              </div>
              <input
                type="text"
                className={this.renderInputClass('offer')}
                placeholder={
                  this.state.form.offer ? this.state.form.offer.title : ''
                }
                disabled
              />
              <Validation isError={this.state.isError.offer}>
                {this.state.isError.offer}
              </Validation>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary relative"
            disabled={this.state.loading}
            onClick={this.onSubmit}
          >
            {submit}
          </button>
        </div>
      </Modal>
    );
  }
}

export default PoiOfferPoiOfferConfirmModal;
