import { FC, HTMLAttributes } from 'react';

import styles from './Card.module.scss';

const Card: FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...rest }) => (
  <section className={styles.card} {...rest}>
    {children}
  </section>
);

export default Card;
