import { makeAutoObservable, runInAction } from 'mobx';
import Vibrant from 'node-vibrant';

import QSRConfig from '../../../qsr.config';
import { shallowCopy } from '../../utils';
import i18n from '../../i18n';
import { getFilename } from '../../utils/store/configStoreHelpers';

import * as Types from './configTypes';
import axios from '../../utils/axios';

interface ICategories {
  [key: string | number]: Types.TPlace[];
}

/**
 * @description Fetch & Store - Application config rules and associations
 */
class ConfigStore {
  API = QSRConfig.API;

  CAMPAIGNS = QSRConfig.CAMPAIGNS;

  GRAPH = QSRConfig.GRAPH;

  THEME = QSRConfig.THEME;

  // eslint-disable-next-line no-underscore-dangle
  _cached = QSRConfig._cached;

  LOGOS = QSRConfig.LOGOS;

  MAP = QSRConfig.MAP;

  KEYS = QSRConfig.KEYS;

  LOCALES = QSRConfig.LOCALES;

  PACKAGE = QSRConfig.PACKAGE;

  BRANDS: { [key: number | string]: Types.IBrand } = {
  };

  COMPETITORS: { [key: number | string]: Types.IBrand } = {
  };

  loading = false;

  imageUpload: any = null;

  constructor() {
    makeAutoObservable(this);
    // extendObservable(this, QSRConfig);
  }

  getCompetitors = async ({ client }: { client?: any; } = {
  }): Promise<void> => {
    // const url = this.API.dashboard.analytics.competitors.list;
    // const category = client.category;

    this.loading = true;

    // const { data }: { data: ICategories } = await axios.post(url, {
    //   categories: [8]
    // });
    const { data }: { data: ICategories } = {
      data: {
        11: [
          {
            placeId: 1006,
            placeName: 'mPower'
          }
        ]
      }
    };

    await runInAction(async () => {
      let competitors = data[8];

      if (!competitors) {
        competitors = [];
      }

      competitors.push({
        placeId: client.placeId,
        placeName: client.name,
      });

      const processBrands = competitors.map(async place => {
        const { key, filename } = getFilename(place, this.LOGOS);

        if (filename) {
          try {
            const bundleLogo = require(`../../assets/logos/companies/${filename}`)
              .default;

            const bundleIcon = require(`../../assets/icons/companies/${filename}`)
              .default;

            const colors = await Vibrant.from(bundleIcon).getPalette();

            runInAction(() => {
              const darkColors = colors?.DarkVibrant ? colors.DarkVibrant.hex : '#000000';
              const lightColors = colors?.Vibrant ? colors?.Vibrant.hex : '#ffffff';

              this.BRANDS[place.placeId] = {
                key,
                filename,
                name: place.placeName,
                paths: {
                  absolute: {
                    icon: `assets/icons/companies/${filename}`,
                    logo: `assets/logos/companies/${filename}`
                  },
                  bundle: {
                    logo: bundleLogo,
                    icon: bundleIcon
                  }
                },
                color: {
                  dark: darkColors,
                  light: lightColors,
                }
              };
            });
          } catch (e) {
            runInAction(() => {
              this.loading = false;
              throw e;
            });
          }

          return true;
        }
      });

      await Promise.all(processBrands);

      runInAction(() => {
        this.loading = false;

        this.COMPETITORS = shallowCopy(this.BRANDS);
        delete this.COMPETITORS[client.placeId];
        this._cached.BRANDS = true;
      });
    });
  }

  setLocation = async ({ refresh = false } = {
  }) => {
    if (this._cached.MAP && !refresh) return this.MAP.center;

    const GeoLocation = window.navigator.geolocation;
    const LocalStorage = window.localStorage;
    const cachedPos = LocalStorage.getItem('cc_user_coord');
    if (cachedPos) {
      const coords = JSON.parse(cachedPos);
      this._cached.MAP = true;
      this.MAP.center = coords;
      return this.MAP.center;
    } if (GeoLocation) {
      return new Promise(resolve => {
        GeoLocation.getCurrentPosition(pos => {
          const { latitude, longitude } = pos.coords;
          LocalStorage.setItem(
            'cc_user_coord',
            JSON.stringify([latitude, longitude])
          );
          this._cached.MAP = true;
          this.MAP.center = [latitude, longitude];
          resolve(this.MAP.center);
        });
      });
    }

    return this.MAP.center;
  }

  postImageToS3 = async ({ image, type = 'homecard' }: { image: any; type: string; }): Promise<void> => {
    let url: string;
    if (type === 'homecard') {
      url = this.API.dashboard.homecard.image;
    } else if (type === 'offer') {
      url = this.API.dashboard.offer.image;
    }

    await runInAction(async () => {
      await runInAction(async () => {
        const isJpgOrPng = image.type === 'image/jpeg' || image.type === 'image/png';

        if (!isJpgOrPng) {
          throw {
            // jpog or png only
            statusCode: 400,
            message: i18n.t('VALIDATION_JPG_PNG')
          };
        }

        const form = new FormData();
        form.append('files', image, image.name);

        try {
          const { data } = await axios.post(url, form, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });

          runInAction(() => {
            this.imageUpload = data;
          });
        } catch (e) {
          runInAction(() => {
            throw Error(i18n.t('ERROR_NETWORK'));
          });
        }
      });
    });
  }
}

export default ConfigStore;
