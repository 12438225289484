import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import qsrConfig from '../../qsr.config';

const defaultLng = qsrConfig.LOCALES.find(
  (l) => l.hasOwnProperty('default') && l.default
);
const i18nConfig = {
  resources: {
  },
  lng: defaultLng ? defaultLng.iso : 'en',
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
};
qsrConfig.LOCALES.forEach((l) => {
  const langData = require(`./locales/${l.locale}.json`);
  const locale = {
    translation: langData[l.locale]
  };
  i18nConfig.resources[l.iso] = locale;
});
i18n.use(initReactI18next).init(i18nConfig);
export default i18n;
