import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {Modal} from 'antd';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import {Map as LeafletMap, TileLayer, Marker} from 'react-leaflet';
import Leaflet from 'leaflet';

@inject(
  'interfaceStore',
  'configStore',
  'locationStore',
  'userStore',
  'analyticsStore'
)
@observer
export class StorePickerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      store: null,
      visible: true
    };
  }

  componentDidMount = async () => {
    try {
      await this.props.locationStore.getClientStores({
        refresh: true
      });
    } catch (e) {
      console.error(e);
    }
  };

  onClick = async (e) => {
    this.setState({
      loading: true
    });
    try {
      if (this.props.interfaceStore.modal.onSubmit) {
        await this.props.interfaceStore.modal.onSubmit(e);
      }
    } catch (e) {
      console.error(e);
    }
    this.onClose();
    this.setState({
      loading: false
    });
  };

  onClose = () => {
    this.setState({
      visible: false
    });
    setTimeout(() => {
      this.props.interfaceStore.hideModal();
    }, 1500);
  };

  renderClientCluster = (cluster) => L.divIcon({
    html: `
      <div class="LeafletMap__cluster LeafletMap__cluster--client">
        <div>
          <span>${cluster.getChildCount()}</span>
        </div>
      </div>
      `
  });

  renderClientStores = () => {
    const placeId = this.props.userStore.client.placeId;
    const stores = this.props.locationStore.clientStoresList;
    const logo = new Leaflet.Icon({
      iconUrl: this.props.configStore.BRANDS[placeId].paths.bundle.icon,
      iconAnchor: [20, 55],
      iconSize: [55, 55]
    });
    return stores.map((s, i) => (
      <Marker
        onClick={this.onClick}
        className={
          this.state.store && this.state.store.id === s.id
            ? 'MarkerHighlight'
            : ''
        }
        key={i}
        storeId={s.id}
        position={[parseFloat(s.latitude), parseFloat(s.longitude)]}
        icon={logo}
        {...s}
      />
    ));
  };

  render() {
    const accessToken = this.props.configStore.KEYS.mapbox;
    const url = this.props.configStore.MAP.url.replace('%TOKEN%', accessToken);
    const {attr} = this.props.configStore.MAP;
    return (
      <Modal
        visible={this.state.visible}
        closable
        destroyOnClose
        footer={null}
        className="Modal StorePickerModal"
        onCancel={this.onClose}
      >
        <LeafletMap
          center={this.props.configStore.MAP.center}
          zoom={this.props.configStore.MAP.zoom}
          className="LeafletMap mt-5 rounded"
        >
          <TileLayer attribution={attr} url={url} />
          <MarkerClusterGroup iconCreateFunction={this.renderClientCluster}>
            {this.props.locationStore.hasStores
              ? this.renderClientStores()
              : null}
          </MarkerClusterGroup>
        </LeafletMap>
      </Modal>
    );
  }
}

export default StorePickerModal;
