import { combineReducers } from 'redux';
import keplerGLReducer from 'kepler.gl/reducers';

const customizedKeplerGlReducer = keplerGLReducer
  .initialState({
    uiState: {
      readOnly: true,
      activeSidePanel: null,
      currentModal: null,
      mapControls: {
      }
    }
  });

const reducers = combineReducers({
  keplerGL: customizedKeplerGlReducer,
});

export default reducers;
