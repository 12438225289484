import { FC } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Menu } from 'antd';
import { RestOutlined, BarChartOutlined, FundOutlined } from '@ant-design/icons';

const AnalyticsEcoBinSubmenu: FC = props => (
  <Menu.SubMenu
    title={(
      <span>
        <RestOutlined />
        <span>
          Eco Bin
        </span>
      </span>
    )}
    {...props}
  >
    {/* <Menu.Item key="/app/analytics/ecobin/statistics">
      <Link to="/app/analytics/ecobin/statistics">
        <span className="nav-text">
          <AreaChartOutlined />
          <span>
            Live Package Status
          </span>
        </span>
      </Link>
    </Menu.Item> */}

    {/* <Menu.Item key="/app/analytics/ecobin/locations">
      <Link to="/app/analytics/ecobin/locations">
        <span className="nav-text">
          <DeleteOutlined />
          <span>
            Live Bin/Package Info
          </span>
        </span>
      </Link>
    </Menu.Item> */}

    {/* <Menu.Item key="/app/analytics/ecobin/package-usage">
      <Link to="/app/analytics/ecobin/package-usage">
        <span className="nav-text">
          <FallOutlined />
          <span>
            Package Life
          </span>
        </span>
      </Link>
    </Menu.Item> */}

    <Menu.Item key="/app/analytics/ecobin/peak-times">
      <Link to="/app/analytics/ecobin/peak-times">
        <span className="nav-text">
          <BarChartOutlined />
          <span>
            Peak Times
          </span>
        </span>
      </Link>
    </Menu.Item>

    <Menu.Item key="/app/analytics/ecobin/scan-report">
      <Link to="/app/analytics/ecobin/scan-report">
        <span className="nav-text">
          <FundOutlined />
          <span>
            Scan Reports
          </span>
        </span>
      </Link>
    </Menu.Item>

  </Menu.SubMenu>
);

export default observer(AnalyticsEcoBinSubmenu);
