import { observable, action, makeAutoObservable, flow, runInAction } from 'mobx';
import { Request, responseFail, configStore } from '..';
import axios from '../../utils/axios';
import sharedStrings from '../../locales/i18n/return/shared';
import formatDate from '../../utils/dateTime/formatDate';
import { rgbToHsl } from 'node-vibrant/lib/util';

class OfferStore {
  rootStore;

  constructor(rootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  allOffers = {
    loading: false,
    data: [],
    error: null
  };

  activeList = null;

  beaconOffers = {
    list: null,
    date: null,
    loading: true,
    offers: null
  };

  poiOfferTiles = {
    options: [
      {
        name: 'Gym', users: 21203
      },
      {
        name: 'QSR', users: 8416
      },
      {
        name: 'Transport', users: 9423
      },
      {
        name: 'Church', users: 35002
      }
    ],
    selected: [],
    send: {
      name: null,
      offer: null
    }
  };

  homeCardList = null;

  getAllOffers = async () => {
    const url = this.rootStore.configStore.API.dashboard.offer.all;
    this.allOffers.loading = true;
    const { data } = await axios.get(url);

    try {
      runInAction(() => {
        this.allOffers.loading = false;
        this.allOffers.data = data;
      });
    } catch (e) {
      runInAction(() => {
        this.allOffers.loading = false;
        this.allOffers.error = e;
      });
    }
  };

  * getActiveOffers({ refresh }) {
    if (!refresh && this.activeList) {
      return this.activeList;
    }
    const url = configStore.API.dashboard.offer.list.active;
    const res = yield Request(url);
    if (responseFail(res)) {
      throw {
        statusCode: res.statusCode || 400,
        message: sharedStrings.error_01
      };
    }
    this.activeList = res.map(offer => ({
      ...offer,
      id: offer.presence_id,
      image: offer.url,
      in_app_title: offer.title,
      in_app_body: offer.body,
      push_title: offer.notification_title,
      push_body: offer.notification_body
    }));
    return this.activeList;
  }

  setActivePoiTile = ({ poi, isActive }) => {
    if (isActive) {
      this.poiOfferTiles.selected.push(poi);
    } else {
      this.poiOfferTiles.selected = this.poiOfferTiles.selected.filter(
        p => p.name !== poi.name
      );
    }
  };

  * setPoiOfferSend({ offer = null, name = null }) {
    if (offer) {
      this.poiOfferTiles.send.offer = offer;
    }
    if (name) {
      this.poiOfferTiles.send.offer = name;
    }
  }

  * poiOfferSend() {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, 3000);
    });
  }

  * getBeaconOfferList({ refresh = false } = {
  }) {
    if (this.beaconOffers.list && !refresh) return this.beaconOffers;
    this.beaconOffers.loading = true;
    const url = configStore.API.dashboard.analytics.beacon.offerList;
    const res = yield Request(url);
    if (responseFail(res)) {
      throw {
        statusCode: res.statusCode || 400,
        message: sharedStrings.error_01
      };
    }
    this.beaconOffers.list = res;
    this.beaconOffers.offers = [];
    this.beaconOffers.loading = false;
    return this.beaconOffers;
  }

  * getBeaconOffer({
    id,
    name,
    dateFrom,
    dateTo,
    refresh = false
  }) {
    if (
      Array.isArray(this.beaconOffers.offers)
      && this.beaconOffers.offers.length
      && !refresh
    ) return this.beaconOffers.offers;
    const url = configStore.API.dashboard.analytics.beacon.campaigns
      .replace('%ID%', id)
      .replace('%DATE_FROM%', formatDate(dateFrom))
      .replace('%DATE_TO%', formatDate(dateTo));
    const res = yield Request(url);
    if (responseFail(res)) {
      throw {
        statusCode: res.statusCode || 400,
        message: sharedStrings.error_01
      };
    }
    res.pop(); // Remove unneeded stats
    const offer = {
      name,
      data: res
    };
    this.beaconOffers.offers.push(offer);
    this.beaconOffers.date = [dateFrom, dateTo];
    return offer;
  }

  sendMapOffer = async (data) => {
    const url = this.rootStore.configStore.API.dashboard.campaign.send;
    const res = await axios.post(url, data);
  };

  * postOffer({ data }) {
    const url = configStore.API.dashboard.offer.post;
    const res = yield Request(url, {
      method: 'POST',
      body: JSON.stringify(data)
    });
    if (responseFail(res)) {
      throw {
        statusCode: res.statusCode || 400,
        message: sharedStrings.error_01
      };
    }
    return res;
  }

  * getHomeCards({ refresh = false } = {
  }) {
    if (this.homeCardList && !refresh) return this.homeCardList;
    const url = configStore.API.dashboard.homecard.list;
    const res = yield Request(url);
    if (responseFail(res)) {
      throw {
        statusCode: res.statusCode || 400,
        message: sharedStrings.error_01
      };
    }
    this.homeCardList = res;
    return this.homeCardList;
  }

  * postHomeCard({ data }) {
    const url = configStore.API.dashboard.homecard.post;
    const res = yield Request(url, {
      method: 'POST',
      body: JSON.stringify(data)
    });
    if (responseFail(res)) {
      throw {
        statusCode: res.statusCode || 400,
        message: sharedStrings.error_01
      };
    }
    return res;
  }
}

export default OfferStore;
