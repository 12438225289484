import { FC } from 'react';
import { observer } from 'mobx-react';

import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { AreaChartOutlined, HomeOutlined, BorderOuterOutlined, PlusOutlined } from '@ant-design/icons';

import sharedStrings from '../../../locales/i18n/return/shared';
import { useStores } from '../../../hooks/useStores';

const SupplierSidebar: FC = props => {
  const { interfaceStore } = useStores();
  const menuTheme = interfaceStore.theme === 'light' ? 'light' : 'dark';
  const supplierHomepage = sharedStrings.suppliers.homepage;

  return (
    <Menu
      theme={menuTheme}
      mode="inline"
    >

      <Menu.Item key="/app">
        <Link to="/app">
          <HomeOutlined />

          <span className="nav-text">
            {supplierHomepage}
          </span>
        </Link>
      </Menu.Item>

      <Menu.ItemGroup title="Management">

        <Menu.SubMenu
          title={(
            <span>
              <BorderOuterOutlined />
              <span>
                Batch Management
              </span>
            </span>
          )}
          {...props}
        >

          <Menu.Item key="/app/create-batch">
            <Link to="/app/create-batch">
              <span className="nav-text">
                <PlusOutlined />
                <span>
                  Create Package Batch
                </span>
              </span>
            </Link>
          </Menu.Item>

          <Menu.Item key="/app/view-batch">
            <Link to="/app/view-batch">
              <span className="nav-text">
                <AreaChartOutlined />
                <span>
                  View Package Batches
                </span>
              </span>
            </Link>
          </Menu.Item>

        </Menu.SubMenu>

      </Menu.ItemGroup>

    </Menu>
  );
};

export default observer(SupplierSidebar);
