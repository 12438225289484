import formatDateTime from '../dateTime/formatDateTime';
import formatDate from '../dateTime/formatDate';

export const transformFeedbackData = feedbackList => {
  const transformedData = feedbackList.map(feedback => {
    const feedbackStore = feedback.store || 'Unknown';
    const formattedDate = formatDateTime(feedback.datetime);
    const feedbackText = feedback.message || 'No Comment';

    return {
      key: `${feedback.id}`,
      feedback_id: `${feedback.id}`,
      custom_id: `${feedbackStore}`,
      email_address: `${feedback.email_address}`,
      date_time: `${formattedDate}`,
      rating: feedback.rating,
      text: `${feedbackText}`,
      reply: feedback.reply || null
    };
  });

  return transformedData;
};

export const getUrl = ({baseUrl, dateFrom, dateTo, page, records}) => {
  let url = `${baseUrl}?records=${records}`;

  if (dateFrom && dateTo) {
    url += `&date_from=${formatDate(dateFrom)}&date_to=${formatDate(dateTo)}`;
  }

  if (page) {
    url += `&page=${page}`;
  }

  return url;
};
