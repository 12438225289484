import React, {Component, Fragment} from 'react';
import {inject, observer} from 'mobx-react';
import OffersSelectCompetitorModal from './OffersSelectCompetitorModal';
import ConfirmModal from './ConfirmModal';
import SuccessModal from './SuccessModal';
import ErrorModal from './ErrorModal';
import StorePickerModal from './StorePickerModal';
import OfferPickerModal from './OfferPickerModal';
import PoiOfferPoiOfferConfirmModal from './PoiOfferConfirmModal';

@inject('interfaceStore')
@observer
export class ModalBus extends Component {
  render() {
    switch (this.props.interfaceStore.modal.active) {
      case 'OFFER_PICKER':
        return <OfferPickerModal />;
      case 'STORE_PICKER':
        return <StorePickerModal />;
      case 'CONFIRM':
        return <ConfirmModal />;
      case 'SUCCESS':
        return <SuccessModal />;
      case 'ERROR':
        return <ErrorModal />;
      case 'OFFERS.SELECT_COMPETITOR':
        return <OffersSelectCompetitorModal />;
      case 'OFFERS.POI_OFFER_CONFIRM':
        return <PoiOfferPoiOfferConfirmModal />;
      default:
        return null;
    }
  }
}

export default ModalBus;
