/* eslint-disable quotes */
import defaultDates from "./defaultDates";
import sharedStrings from "../../locales/i18n/return/shared";

const last7Days = sharedStrings.date_picker.last_7_days;
const last14Days = sharedStrings.date_picker.last_14_days;
const last21Days = sharedStrings.date_picker.last_21_days;
// const last31Days = sharedStrings.date_picker.last_31_days;
const lastMonth = sharedStrings.date_picker.last_month;
const last3Months = sharedStrings.date_picker.last_3_months;

const presetDates = {
  default: [defaultDates.seven_days_ago, defaultDates.today],
  ranges: {
    [last7Days]: [defaultDates.seven_days_ago, defaultDates.yesterday],
    [last14Days]: [defaultDates.fourteen_days_ago, defaultDates.yesterday],
    [last21Days]: [defaultDates.twentyone_days_ago, defaultDates.yesterday],
    // [last31Days]: [defaultDates.thirtyone_days_ago, defaultDates.yesterday],
    [lastMonth]: [
      defaultDates.start_day_last_month,
      defaultDates.end_day_last_month,
    ],
    [last3Months]: [
      defaultDates.start_of_day_3_months,
      defaultDates.end_day_last_month,
    ],
  },
};

export default presetDates;
