import {makeAutoObservable} from 'mobx';
import axios from '../../utils/axios';
import ResponseError from '../../utils/axios/ResponseError';
import {transformFeedbackData, getUrl} from '../../utils/store/feedbackStoreHelpers';

/**
 * @description Fetch & Store - User & store feedback
 */
class FeedbackStore {
  feedbackList = [];

  dates = null;

  error = null;

  updateError = null;

  loading = false;

  isBlocked = false;

  totalPages = null;

  totalRecords = null;

  currentPage = null;

  pageSize = null;

  constructor(rootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  get = function* (dateFrom, dateTo, page, records = 5) {
    this.pageSize = records;

    const baseUrl = this.rootStore.configStore.API.dashboard.feedback.get;
    const url = getUrl({
      baseUrl, dateFrom, dateTo, page, records
    });

    try {
      this.loading = true;
      this.error = null;
      const response = yield axios.get(`${url}`);

      this.loading = false;
      const feedbackList = response.data.data;
      this.feedbackList = feedbackList;
      this.feedbackList = transformFeedbackData(feedbackList);
      this.dates = [dateFrom, dateTo];

      if (dateFrom && dateTo && page) {
        this.currentPage = page;
        this.totalPages = response.data.pagination.totalPages;
        this.totalRecords = response.data.pagination.totalRecords;
      }
    } catch (error) {
      this.loading = false;
      const statusCode = error.response.data.statusCode;
      const message = error.response.data.message;
      this.error = {
        statusCode, message,
      };

      throw new ResponseError(this.error);
    }
  }

  reply = function* (id, data) {
    const baseUrl = this.rootStore.configStore.API.dashboard.feedback.reply.replace('%ID%', id);
    const dateFrom = this.dates[0] || null;
    const dateTo = this.dates[1] || null;
    const url = getUrl({
      baseUrl,
      dateFrom,
      dateTo,
      page: this.currentPage,
      records: this.pageSize,
    });

    try {
      this.loading = true;
      this.isBlocked = true;
      this.updateError = null;

      const response = yield axios.post(`${url}`, {
        reply: data
      });

      this.loading = false;
      this.isBlocked = false;
      const updatedFeedbackList = response.data.data;
      this.feedbackList = transformFeedbackData(updatedFeedbackList);
    } catch (error) {
      this.loading = false;
      this.isBlocked = false;
      const statusCode = error.response.data.statusCode;
      const message = error.response.data.message;
      this.updateError = {
        statusCode, message,
      };

      throw new ResponseError(this.updateError);
    }
  }

  delete = function* (id) {
    const baseUrl = this.rootStore.configStore.API.dashboard.feedback.delete.replace('%ID%', id);
    const dateFrom = this.dates[0] || null;
    const dateTo = this.dates[1] || null;
    const url = getUrl({
      baseUrl,
      dateFrom,
      dateTo,
      page: this.currentPage,
      records: this.pageSize,
    });

    try {
      this.loading = true;
      this.isBlocked = true;
      this.updateError = null;
      const response = yield axios.delete(`${url}`);

      this.loading = false;
      this.isBlocked = false;
      const updatedFeedbackList = response.data.data;
      this.feedbackList = transformFeedbackData(updatedFeedbackList);
    } catch (error) {
      this.loading = false;
      this.isBlocked = false;
      const statusCode = error.response.data.statusCode;
      const message = error.response.data.message;
      this.updateError = {
        statusCode, message,
      };

      throw new ResponseError(this.updateError);
    }
  }
}

export default FeedbackStore;
