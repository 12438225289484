import { FC } from 'react';
import { observer } from 'mobx-react';

import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';

import sharedStrings from '../../../locales/i18n/return/shared';
import { useStores } from '../../../hooks/useStores';
// import ManageEcoBinSubmenu from '../Submenus/VenueNow/Manage/ManageEcoBinSubmenu';
// import ManageHomecardSubmenu from '../Submenus/VenueNow/Manage/ManageHomecardSubmenu';
import AnalyticsEcoBinSubmenu from '../Submenus/VenueNow/Analytics/AnalyticsEcoBinSubmenu';
// import AnalyticsOffersSubmenu from '../Submenus/VenueNow/Analytics/AnalyticsOffersSubmenu';
import ManageVenueSubMenu from '../Submenus/VenueNow/Manage/ManageVenueSubMenu';
import ManageCreateCampaignSubmenu from '../Submenus/VenueNow/Manage/ManageCreateCampaignSubmenu';
import ManageOfferSubmenu from '../Submenus/VenueNow/Manage/ManageOfferSubmenu';

const VenueNowSidebar: FC = () => {
  const { interfaceStore } = useStores();
  const menuTheme = interfaceStore.theme === 'light' ? 'light' : 'dark';
  const venueNowHomepage = sharedStrings.venueNow.homepage;

  return (
    <Menu
      theme={menuTheme}
      mode="inline"
    >

      <Menu.Item key="/app">
        <Link to="/app">
          <HomeOutlined />
          <span className="nav-text">
            {venueNowHomepage}
          </span>
        </Link>
      </Menu.Item>

      <Menu.ItemGroup title="Management">

        <ManageCreateCampaignSubmenu key="campaign-mngmnt-submenu" />

        <ManageVenueSubMenu key="location-mngmnt-submenu" />

        <ManageOfferSubmenu key="offer-submenu" />

        {/* <ManageEcoBinSubmenu key="eco-bin-mngmnt-submenu" /> */}

        {/* <ManageHomecardSubmenu key="homecard-mngmnt-submenu" /> */}

      </Menu.ItemGroup>

      <Menu.ItemGroup title="Analytics" />

      <AnalyticsEcoBinSubmenu key="eco-bin-analytics-submenu" />

      {/* <AnalyticsOffersSubmenu key="analytics-offers-submenu" /> */}

    </Menu>
  );
};

export default observer(VenueNowSidebar);
