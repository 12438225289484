import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {inject, observer} from 'mobx-react';
import i18n from '../../i18n';
import Spinner from '../Blocks/Spinner';

@inject('interfaceStore')
@observer
export class SuccessModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: true
    };
  }

  componentDidMount = () => {};

  onClose = () => {
    this.setState({
      visible: false
    });
    setTimeout(() => {
      this.props.interfaceStore.hideModal();
    }, 1500);
  };

  onSubmit = async () => {
    this.onClose();
  };

  render() {
    const message = i18n.t('SUCCESS');
    const submit = i18n.t('OK');
    return (
      <Modal
        show={this.state.visible}
        className="pt-6 Modal SuccessModal"
        onHide={this.onClose}
      >
        <div className="modal-header">
          <button type="button" className="close" onClick={this.onClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <span>{message}</span>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary relative"
            disabled={this.state.loading}
            onClick={this.onSubmit}
          >
            {submit}
          </button>
        </div>
      </Modal>
    );
  }
}

export default SuccessModal;
